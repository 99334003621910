/* Root Variables */
:root {
  /* Theme Colors */
  --color-primary: #BB86FC;
  --color-secondary: #64FFDA;
  --color-background: #1A1A1A;
  --color-surface: rgb(255 255 255 / 5%);
  --color-error: #FF453A;
  --color-text: #FFF;
  --color-text-secondary: rgb(255 255 255 / 70%);
  
  /* Shadows */
  --shadow-sm: 0 2px 12px -2px rgb(0 0 0 / 15%);
  --shadow-md: 0 4px 16px -2px rgb(0 0 0 / 20%);
  --shadow-lg: 0 6px 20px -2px rgb(0 0 0 / 30%);
  
  /* Glassmorphism */
  --glassmorphism-border: 1px solid rgb(255 255 255 / 8%);
  --glassmorphism-highlight: inset 0 1px 0 0 rgb(255 255 255 / 5%);
  --glass-blur: 16px;
  --glass-base-opacity: 0.05;
  --glass-border-opacity: 0.1;
  --glass-highlight-opacity: 0.05;
  
  /* Effect Intensities */
  --hover-intensity: 0.1;
  --active-intensity: 0.15;
  --border-intensity: 0.2;
}

.wellness-container {
  font-family: Roboto, sans-serif;
  background: transparent;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  letter-spacing: 0.2px;
  position: relative;
  overflow-x: hidden;

}

.wellness-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.wellness-content-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
  height: 100%;
}

.wellness-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 24px;
  height: 100%;
}

/* Panel Base Styles */
.wellness-panel-left,
.wellness-panel-right {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb, 255, 255, 255), 0.03) 0%,
    rgba(var(--primary-rgb, 255, 255, 255), 0.05) 100%
  );
  backdrop-filter: blur(var(--glass-blur));
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 48px);
  overflow-y: auto;
  position: sticky;
  top: 24px;
  box-shadow: 
    0 4px 16px -2px rgb(0 0 0 / 20%),
    inset 0 1px 0 0 rgb(255 255 255 / 5%);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Typography Base Styles */
.wellness-container h3 {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
  color: var(--text-primary, var(--color-text));
}

.wellness-container h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-secondary, var(--color-text-secondary));
  margin-bottom: 8px;
}

/* Calendar Container */
.wellness-calendar-container {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb, 255, 255, 255), 0.02) 0%,
    rgba(var(--primary-rgb, 255, 255, 255), 0.04) 100%
  );
  backdrop-filter: blur(var(--glass-blur));
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  width: 100%;
  box-shadow: 
    var(--shadow-md),
    var(--glassmorphism-highlight);
}

/* Calendar Header */
.wellness-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.wellness-month-picker {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.wellness-nav-button {
  background: none;
  border: none;
  color: var(--primary, var(--color-primary));
  font-size: 1.4rem;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.2s ease;
}

.wellness-nav-button:hover {
  transform: translateY(-1px);
}

/* Calendar Grid */
.wellness-calendar-weekday, 
.wellness-calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.wellness-calendar-weekday div {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-secondary, var(--color-text-secondary));
  padding: 8px;
  text-transform: uppercase;
}

/* Calendar Day Styling */
.wellness-calendar-day {
  aspect-ratio: 1;
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--text-primary, var(--color-text));
  box-shadow: 
    var(--shadow-sm),
    var(--glassmorphism-highlight);
}

.wellness-calendar-day-inactive {
  opacity: 0.3;
  cursor: default;
  background: rgb(255 255 255 / 2%);
  backdrop-filter: none;
  box-shadow: none;
}

.wellness-calendar-day:hover:not(.wellness-calendar-day-inactive) {
  background: rgba(var(--primary-rgb, 255, 255, 255), var(--hover-intensity));
  transform: translateY(-2px);
  border-color: rgba(var(--primary-rgb, 255, 255, 255), var(--border-intensity));
  box-shadow: 
    var(--shadow-md),
    var(--glassmorphism-highlight);
}

.wellness-calendar-current-day {
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.15);
  border-color: rgba(var(--primary-rgb, 255, 255, 255), 0.4);
}

.wellness-calendar-selected {
  background: rgba(var(--primary-rgb, 255, 255, 255), var(--active-intensity));
  border-color: rgba(var(--primary-rgb, 255, 255, 255), var(--border-intensity));
  color: var(--text-primary, var(--color-text));
  font-weight: 600;
}

/* Stats Panel */
.wellness-stats-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.wellness-stat-item {
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  box-shadow: 
    var(--shadow-sm),
    var(--glassmorphism-highlight);
}

.wellness-stat-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wellness-stat-info span {
  font-size: 1rem;
  color: var(--text-secondary, var(--color-text-secondary));
  font-weight: 500;
}

.wellness-stat-info strong {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary, var(--color-text));
}

.wellness-time-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.wellness-time-slot {
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  backdrop-filter: blur(12px);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary, var(--color-text));
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 
    var(--shadow-sm),
    var(--glassmorphism-highlight);
}

.wellness-time-slot:hover {
  background: rgba(var(--primary-rgb, 255, 255, 255), var(--hover-intensity));
  transform: translateY(-2px);
  border-color: var(--primary);
  box-shadow: 
    var(--shadow-md),
    var(--glassmorphism-highlight),
    0 0 8px rgba(var(--primary-rgb, 255, 255, 255), 0.3);
}

.wellness-time-slot-active {
  background: rgba(var(--primary-rgb, 255, 255, 255), var(--active-intensity));
  border: 2px solid var(--primary);
  color: var(--primary);
  font-weight: 600;
  box-shadow: 
    0 0 12px rgba(var(--primary-rgb, 255, 255, 255), 0.2),
    var(--glassmorphism-highlight);
}

.wellness-schedule-button {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb, 255, 255, 255), 0.3) 0%,
    rgba(var(--primary-rgb, 255, 255, 255), 0.15) 100%
  );
  backdrop-filter: blur(12px);
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.3);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary, var(--color-text));
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 
    0 4px 20px -4px rgba(var(--primary-rgb, 255, 255, 255), 0.3),
    inset 0 1px 0 0 rgb(255 255 255 / 10%);
  position: relative;
  overflow: hidden;
  margin: 16px 0;
}

.wellness-schedule-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgb(255 255 255 / 10%) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}



.wellness-schedule-button:hover:not(:disabled) {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb, 255, 255, 255), 0.4) 0%,
    rgba(var(--primary-rgb, 255, 255, 255), 0.25) 100%
  );
  transform: translateY(-2px);
  box-shadow: 
    0 8px 24px -4px rgba(var(--primary-rgb, 255, 255, 255), 0.4),
    inset 0 1px 0 0 rgb(255 255 255 / 20%);
}

.wellness-schedule-button:hover:not(:disabled)::before {
  transform: translateX(100%);
}


.wellness-schedule-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: 
    0 4px 16px -4px rgba(var(--primary-rgb, 255, 255, 255), 0.3),
    inset 0 1px 0 0 rgb(255 255 255 / 10%);
}

.wellness-schedule-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  border-color: rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  box-shadow: none;
}

@keyframes shimmer {
  0% {
    background-position: -200% center;
  }

  100% {
    background-position: 200% center;
  }
}


/* Session Sections */
.wellness-meditation-scheduler {
  padding: 20px;
}

.wellness-scheduling-header {
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  border-radius: 10px;
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
}

.wellness-scheduling-date {
  font-size: 1.1rem;
  color: var(--text-primary, var(--color-text));
  font-weight: 500;
}

.wellness-sessions-section {
  margin-top: 24px;
  padding: 20px 0;
  border-top: 1px solid rgb(255 255 255 / 10%);
}

.wellness-sessions-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

/* Session Items (continued) */
.wellness-session-item {
  box-shadow: 
    var(--shadow-sm),
    var(--glassmorphism-highlight);
}

.wellness-session-item:hover {
  background: rgba(var(--primary-rgb, 255, 255, 255), var(--hover-intensity));
  border-color: rgba(var(--primary-rgb, 255, 255, 255), var(--border-intensity));
  transform: translateY(-2px);
  box-shadow: 
    var(--shadow-md),
    var(--glassmorphism-highlight);
}

.wellness-session-time {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary, var(--color-text));
}

.wellness-session-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wellness-session-title {
  font-weight: 500;
  color: var(--text-primary, var(--color-text));
}

.wellness-duration {
  font-size: 0.9rem;
  color: var(--text-secondary, var(--color-text-secondary));
}

.wellness-session-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.wellness-pending-icon {
  color: var(--secondary, var(--color-secondary));
  opacity: 0.7;
}

.wellness-delete-btn {
  color: var(--color-error);
  cursor: pointer;
  transition: transform 0.2s ease;
}

.wellness-delete-btn:hover {
  transform: scale(1.1);
}

.wellness-no-sessions {
  text-align: center;
  color: var(--text-secondary, var(--color-text-secondary));
  padding: 24px;
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  border-radius: 10px;
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
}

.wellness-date-label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-secondary, var(--color-text-secondary));
  margin-top: 16px;
  padding: 8px 0;
}

/* Select Prompt */
.wellness-select-prompt {
  text-align: center;
  padding: 48px 24px;
  background: rgba(var(--primary-rgb, 255, 255, 255), 0.03);
  border-radius: 10px;
  border: 1px solid rgba(var(--primary-rgb, 255, 255, 255), 0.1);
  color: var(--text-secondary, var(--color-text-secondary));
}

.wellness-select-prompt p {
  margin-top: 16px;
  font-size: 1.1rem;
}

/* Aurora Background Effect */
.wellness-aurora-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: 0.3;
  background-image: radial-gradient(
    circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(var(--primary-rgb, 255, 255, 255), 0.15),
    rgba(var(--primary-rgb, 255, 255, 255), 0.1),
    transparent 60%
  );
  mix-blend-mode: screen;
  filter: blur(80px) contrast(120%);
  transform-origin: center;
  animation: auroraShift 15s infinite ease-in-out;
  z-index: -1;
}

.wellness-aurora-bg::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(var(--primary-rgb, 255, 255, 255), 0.1),
    rgba(var(--primary-rgb, 255, 255, 255), 0.05),
    transparent 60%
  );
  mix-blend-mode: screen;
  filter: blur(40px);
  animation: auroraWaves 20s infinite;
}

@media (width <= 1024px) {
  .wellness-page {
    height: auto;
    min-height: 100vh;
    overflow: visible;
  }

  .wellness-content {
    grid-template-columns: 1fr;
    height: auto;
    gap: 16px;
  }

  .wellness-content-wrapper {
    height: auto;
    overflow: visible;
  }

  .wellness-panel-left,
  .wellness-panel-right {
    height: auto;
    max-height: none;
    position: static;
    overflow: visible;
    margin-bottom: 16px;
  }

  .wellness-calendar-container {
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
  }

  .wellness-meditation-scheduler {
    padding: 16px;
    overflow: visible;
  }

  .wellness-sessions-list {
    overflow: visible;
  }
}

@media (width <= 640px) {
  .wellness-content {
    padding: 12px;
  }

  .wellness-panel-left,
  .wellness-panel-right {
    padding: 16px;
  }

 
    .wellness-time-slots {
      flex-direction: column;
    }
  
    .wellness-time-slot {
      width: 100%;
      text-align: center;
    }
  

  .wellness-stat-item {
    flex: 1 1 100%;
  }

  .wellness-sessions-section {
    margin-top: 16px;
    padding: 16px 0;
  }
}

/* Animations */
@keyframes auroraShift {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes auroraWaves {
  0%, 100% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.8;
  }
}