
.theme-customizer-panel {
    background-color: var(--background-color, #1e1e1e);
    color: var(--text-color, #fff);
    border-radius: 12px;
    padding: 16px;
    min-width: 240px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 50%); 
    border: 1px solid rgb(255 255 255 / 20%);
    position: fixed;
    opacity: 0;
    pointer-events: none; 
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease; 
}


.theme-customizer-panel.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.theme-customizer-panel .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.theme-customizer-panel .panel-header h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: var(--text-primary, #fff); 
}

.theme-customizer-panel .panel-close-btn {
    background: none;
    border: none;
    color: var(--text-secondary, rgb(255 255 255 / 60%));
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.theme-customizer-panel .panel-close-btn:hover {
    background-color: rgb(255 255 255 / 10%);
}


.color-theme-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

.color-theme-button {
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
    background-color: var(--background-light, #2c2c2c); 
}

.color-theme-button .color-preview {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.color-theme-button.active {
    border-color: var(--primary, #6A00FF);
    transform: scale(1.05);
}

.color-theme-button:hover .color-preview {
    transform: scale(1.1); 
}
