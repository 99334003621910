.site-header {
    position: fixed;
    top: -100px; /* Start hidden */
    left: 0;
    right: 0;
    z-index: 50;
    padding: 0.5rem 2rem;
    transition: top 0.3s ease; /* Smooth sliding effect */
    background: rgb(0 0 0 / 80%);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  }
  
  .site-header.scrolled {
    background: rgb(0 0 0 / 29.5%);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  }
  
  .site-header.visible {
    top: 0; /* Slide in when visible */
  }
  
  .site-header:hover {
    top: 0; /* Ensure header stays visible on hover */
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  /* Left side content */
  .left-content {
    display: flex;
    align-items: center;
    gap: 4rem;
  }
  
  .brand-text {
    color: #6301db;
    font-size: 1.25rem;
    font-family: Selecta-regular, sans-serif;
    text-decoration: none;
    transition: opacity 0.2s ease;
  }
  
  .brand-text:hover {
    opacity: 0.8;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
  
  .nav-link {
    color: white;
    background: none; /* Ensure no background is applied */
    border: none;
    font-size: 0.95rem;
    opacity: 0.9;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: opacity 0.3s ease;
    position: relative;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: #BB86FC; /* Color of the animated underline */
    transition: width 0.3s ease;
    transform: translateX(-50%);
  }
  
  .nav-link:hover::after,
  .nav-link.active::after {
    width: 100%; /* Animate underline */
  }
  
  .nav-link:hover {
    opacity: 1; /* Adjust opacity on hover */
    background: none; /* Ensure no background appears */
  }
  
  /* Right side auth */
  .auth-section {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-left: auto;
  }
  
  .sign-in {
    color: white;
    text-decoration: none;
    opacity: 0.9;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .sign-in::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 1px;
    background: white;
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }
  
  .sign-in:hover::after {
    width: 100%;
  }
  
  .get-started {
    background: #BB86FC;
    color: black;
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .get-started:hover {
    transform: translateY(-2px);
    background: #c397ff;
    box-shadow: 0 4px 20px rgb(187 134 252 / 30%);
  }
  
  .menu-button {
    display: none;
    background: transparent;
    border: 1px solid #BB86FC;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    color: #BB86FC;
    transition: all 0.3s ease;
  }
  
  .menu-button:hover,
  .menu-button.active {
    background: #BB86FC;
    color: black;
  }
  
  /* Mobile Menu */
  .mobile-menu {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 95%);
    backdrop-filter: blur(10px);
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .mobile-menu.open {
    max-height: 100vh;
    padding: 2rem;
    border-bottom: 1px solid rgb(187 134 252 / 20%);
  }
  
  .mobile-nav {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.3s ease;
  }
  
  .mobile-menu.open .mobile-nav {
    opacity: 1;
    transform: translateY(0);
  }
  
  .mobile-nav-link {
    color: white;
    background: none; /* Ensure no background effect */
    border: none;
    font-size: 1.1rem;
    padding: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 0.8;
    text-align: left;
    border-radius: 8px; /* Keep rounded corners if needed */
  }
  
  .mobile-nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: #BB86FC;
    transition: width 0.3s ease;
    transform: translateX(-50%);
  }
  
  .mobile-nav-link:hover::after,
  .mobile-nav-link.active::after {
    width: 100%; /* Animate underline */
  }
  
  .mobile-nav-link:hover {
    opacity: 1; /* Adjust opacity on hover */
    background: none; /* Ensure no background appears */
  }
  
  /* Mobile auth */
  .mobile-auth {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgb(255 255 255 / 10%);
  }
  
  .mobile-sign-in {
    color: white;
    text-decoration: none;
    padding: 1rem;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    text-align: center;
  }
  
  .mobile-get-started {
    background: #BB86FC;
    color: black;
    padding: 1rem;
    border-radius: 8px;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .mobile-get-started:hover {
    background: #c397ff;
  }
  
  /* Responsive */
  @media (width <= 1200px) {
    .site-header {
      padding: 1.5rem 2rem;
    }
  
    .left-content {
      gap: 3rem;
    }
  
    .nav-links {
      gap: 2rem;
    }
  }
  
  @media (width <= 992px) {
    .left-content {
      gap: 2rem;
    }
  
    .nav-links {
      gap: 1.5rem;
    }
  
    .auth-section {
      gap: 1.5rem;
    }
  }
  
  @media (width <= 768px) {
    .nav-links,
    .sign-in,
    .get-started {
      display: none;
    }
  
    .menu-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media (width <= 480px) {
    .site-header {
      padding: 1.25rem 1.5rem;
    }
  }
  