/* Base styles */
body, input, button, textarea {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #fff;
  background-color: #000 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main application container */
.App {
  background-color: #000 !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  overflow: visible;
}

/* Logo */
.app-logo {
  height: 30px;
  width: auto;
  margin: 0 auto;
  margin-top: 35px;
}

/* Meditation Content */
.meditation-content {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Audio Controls Container - Parent of both AudioPlayer and VolumeControls */
.audio-controls-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  background-color: transparent;
}

/* Audio Player Container */
.audio-player-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
  box-sizing: border-box;
  background-color: #0f1012;
  border-radius: 30px;
  box-shadow: 20px 20px 60px #0c0d0e, -20px -20px 60px #181b1e;
  overflow: hidden;
}

/* Input Elements */
.select, .input, .slider-container {
  margin: 1rem 0;
  color: #fff;
  width: 100%;
  max-width: 100%;
}

/* Wave Button and Tooltip */
.wave-btn-container {
  position: relative;
  display: inline-block;
  margin: 1rem;
}

.wave-btn {
  border-radius: 50px !important;
  background: linear-gradient(145deg, #1a1a1a, #080808);
  box-shadow: 
    8px 8px 16px #000,
    -8px -8px 16px #2a2a2a;
  border: none;
  font-size: 1.25rem;
  padding: 0.8em 2.4em !important;
  min-height: 44px !important;
  min-width: 120px !important;
  cursor: pointer;
  position: relative;
  color: #e0e0e0;
  transition: all 0.3s ease;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  appearance: none;
}

.wave-btn-tooltip {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 20px;
  background: linear-gradient(145deg, #1a1a1a, #080808);
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  width: max-content;
  max-width: 250px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 
    5px 5px 10px #000,
    -5px -5px 10px #2a2a2a,
    inset 1px 1px 2px rgb(255 255 255 / 5%);
  z-index: 1000;
}

.wave-btn-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #1a1a1a transparent transparent;
}

.wave-btn-container:hover .wave-btn-tooltip {
  opacity: 1;
  visibility: visible;
  bottom: calc(100% + 10px);
}

/* Error Message Styles */
.error-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  color: #991b1b;
  transition: all 0.2s ease;
  box-shadow: 3px 3px 6px #000, -3px -3px 6px #2a2a2a;
}

.error-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-icon {
  font-size: 16px;
}

.error-text {
  font-size: 14px;
  line-height: 1.5;
}

.error-dismiss {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #991b1b;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.error-dismiss:hover {
  opacity: 1;
}

/* Error Type Styles */
.error-audio { background-color: #eff6ff; border-color: #dbeafe; color: #1e40af; }
.error-mixing { background-color: #f0fdf4; border-color: #dcfce7; color: #166534; }
.error-script { background-color: #fdf4ff; border-color: #fae8ff; color: #86198f; }
.error-session { background-color: #fff7ed; border-color: #ffedd5; color: #9a3412; }
.error-music { background-color: #f5f3ff; border-color: #ede9fe; color: #5b21b6; }

/* Toast Styles */
.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast {
  background-color: #16181a;
  border-radius: 12px;
  box-shadow: 8px 8px 16px #0c0d0e, -8px -8px 16px #202326;
  margin-bottom: 1rem;
  min-height: 64px;
  padding: 12px 16px;
}

.Toastify__toast-body {
  color: #fff;
  font-family: inherit;
  padding: 0;
  margin: 0;
  gap: 12px;
}

/* Toast Variations */
.Toastify__toast--success {
  background: linear-gradient(145deg, #1a1d20, #16181a);
  border-left: 4px solid #7d12ff;
}

.Toastify__toast--error {
  background: linear-gradient(145deg, #1a1d20, #16181a);
  border-left: 4px solid #ff4d4d;
}

.Toastify__toast--info {
  background: linear-gradient(145deg, #1a1d20, #16181a);
  border-left: 4px solid #3498db;
}

.Toastify__toast--warning {
  background: linear-gradient(145deg, #1a1d20, #16181a);
  border-left: 4px solid #f1c40f;
}

/* Toast Icons */
.Toastify__toast--success .Toastify__toast-icon { color: #7d12ff; }
.Toastify__toast--error .Toastify__toast-icon { color: #ff4d4d; }
.Toastify__toast--info .Toastify__toast-icon { color: #3498db; }
.Toastify__toast--warning .Toastify__toast-icon { color: #f1c40f; }

/* Close Button */
.Toastify__close-button {
  color: #a0a0a0;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  align-self: center;
}

.Toastify__close-button:hover {
  opacity: 1;
  color: #fff;
}

/* Progress Bar */
.Toastify__progress-bar {
  background: linear-gradient(to right, rgba(125, 18, 255, 0.2), rgba(125, 18, 255, 0.4));
  height: 3px;
}

.Toastify__progress-bar--success {
  background: linear-gradient(to right, rgba(125, 18, 255, 0.3), #7d12ff);
}

.Toastify__progress-bar--error {
  background: linear-gradient(to right, rgba(255, 77, 77, 0.3), #ff4d4d);
}

.Toastify__progress-bar--info {
  background: linear-gradient(to right, rgba(52, 152, 219, 0.3), #3498db);
}

.Toastify__progress-bar--warning {
  background: linear-gradient(to right, rgba(241, 196, 15, 0.3), #f1c40f);
}

/* Toast Content */
.toast-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toast-content .icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.toast-content .message {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  flex-grow: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .App {
    padding: 1rem 0.5rem;
  }

  .audio-controls-container {
    padding: 10px;
    gap: 20px;
    margin: 0.5rem auto;
  }

  .audio-player-container {
    padding: 15px;
    margin: 0.5rem auto;
  }

  .Toastify__toast {
    margin: 0.5rem;
    min-height: 56px;
    padding: 10px 14px;
  }

  .toast-content .message {
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 0.5rem 0.25rem;
  }

  .audio-controls-container {
    padding: 8px;
    gap: 15px;
  }

  .audio-player-container {
    padding: 10px;
    margin: 0.25rem auto;
  }

  .Toastify__toast {
    margin: 0.375rem;
    min-height: 48px;
    padding: 8px 12px;
  }

  .toast-content .message {
    font-size: 0.75rem;
  }

  .toast-content .icon {
    width: 16px;
    height: 16px;
  }
}

/* iOS Fixes */
@supports (-webkit-touch-callout: none) {
  .wave-btn {
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 50px !important;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
  }
}