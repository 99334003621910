/* Base Layout */
.check-email-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    #050508 0%,
    #080818 25%,
    #0a0a20 50%,
    #0c0c25 75%,
    #0e0e2a 100%
  );
  padding: 20px;
}

.background-layer {
  position: fixed;
  inset: 0;
  background: radial-gradient(circle, rgb(137 181 240 / 7%) 0%, transparent 100%);
  z-index: 0;
}

/* Content Container */
.check-email-container {
  max-width: 480px;
  padding: 48px;
  background: rgb(26 26 46 / 60%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgb(0 0 0 / 40%);
  backdrop-filter: blur(12px);
  text-align: center;
  animation: fadeInUp 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Title and Subtitle */
.check-email-title {
  font-size: 2rem;
  font-weight: 700;
  color: #e3e3ff;
  margin-bottom: 1rem;
}

.check-email-subtitle {
  font-size: 1rem;
  color: rgb(220 220 255 / 80%);
  margin-bottom: 2rem;
  line-height: 1.6;
}

/* Buttons */
.auth-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background: linear-gradient(90deg, #6b8dfd, #bda6fd);
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.auth-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgb(137 181 240 / 30%);
}

.auth-button.secondary {
  background: transparent;
  border: 1px solid rgb(255 255 255 / 30%);
  color: rgb(220 220 255 / 80%);
}

.auth-button.secondary:hover {
  background: rgb(137 181 240 / 10%);
}

.auth-footer {
  margin-top: 2rem;
  color: rgb(220 220 255 / 60%);
  font-size: 0.9rem;
}

.auth-link {
  color: #bda6fd;
  text-decoration: none;
  transition: color 0.3s ease;
}

.auth-link:hover {
  color: #fff;
}
