/* Base Variables */
.app-container {
  --space-sm: 8px;
  --space-md: 16px;
  --radius-full: 50%;
  --radius: 12px;
  --transition-fast: 0.2s;
}

/* Container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

/* Main Content Area */
.content-area {
  flex: 1;
  overflow-y: auto;
  position: relative;
  margin-left: 250px;
  height: calc(100vh - 64px); /* Account for header height */
  transition: margin-left 0.3s ease-in-out;
  background-color: var(--background-color);
}

/* Sidebar Toggle Button */
.sidebar-toggle-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--text-secondary);
  margin-right: var(--space-md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: var(--radius);
  transition: all var(--transition-fast);
}

.sidebar-toggle-btn:hover {
  color: var(--text-primary);
  background-color: var(--hover-overlay);
}

/* Theme Toggle */
.theme-toggle {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: var(--radius);
}

.theme-toggle:hover {
  color: var(--text-primary);
  background-color: var(--hover-overlay);
}

/* Navigation Items */
.nav-item {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  color: var(--nav-item-color);
  text-decoration: none;
  transition: all var(--transition-fast);
  margin: 4px 12px;
  border-radius: var(--radius);
}

.nav-item:hover {
  background-color: var(--hover-overlay);
}

.nav-item.active {
  color: var(--nav-item-active-color);
  background-color: var(--nav-badge-background);
}

/* Notifications Panel */
.notifications-panel {
  position: fixed;
  top: 64px;
  right: 0;
  width: 320px;
  max-height: calc(100vh - 64px);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  z-index: 10;
  overflow-y: auto;
}

/* Main Layout Structure */
.flex {
  display: flex;
}

.h-\[calc\(100vh-64px\)\] {
  height: calc(100vh - 64px);
  overflow: hidden; /* Contain the scrolling to content-area */
}

/* Responsive Adjustments */
@media (width <= 768px) {
  .content-area {
    margin-left: 0;
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .app-container {
    overflow-x: hidden;
  }

  .notifications-panel {
    width: 100%;
  }
}