/* Root Variables */
:root {
  /* Colors */
  --main-bg-color: #000;
  --neomorphic-light: #2c2f33;
  --neomorphic-dark: #16181a;
  --text-color: #fff;
  --guided-color: #0cffdf;
  --personalized-color: #520250;
  --teal-color: teal;
  --dark-purple-color: darkpurple;

  /* Spacing & Sizing */
  --gap-size: 20px;
  --button-padding: 12px 25px;
  --pill-border-radius: 50px;
  --border-gradient-width: 2px;
  --thin-border-width: 1px;

  /* Effects */
  --glow-softness: 10px;
  --shadow-strength: 4px;
  --fade-duration: 0.5s;
  --scale-duration: 0.5s;
}

/* Decision Tree Container */
.decision-tree-container {
  background: transparent;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-size);
  position: relative;
  z-index: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  transform: translateY(-20px);

}


/* Add this class */
.decision-tree-container.visible {
  opacity: 1;
  transform: translateY(0);
}


.decision-tree-container.open {
  opacity: 1;
  transform: translateY(0);
}


/* Decision Tree Node */
.decision-tree-node {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  transform: scale(0.95);
}

.decision-tree-node.expanded {
  opacity: 1;
  transform: scale(1);
}


.decision-tree-node.expanded .decision-tree-options {
  opacity: 1;
  transform: translateY(0);
}

/* Prompt Styles */
.decision-tree-prompt {
  font-size: 1.4rem;
  color: var(--text-color);
  margin-bottom: 25px;
  text-align: center;
  line-height: 1.5;
  width: 100%;
}

/* Options */
.decision-tree-options {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-size);
  justify-content: center;
  margin-top: 10px;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  transform: translateY(-10px);
}


.decision-tree-option {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, var(--gradient-start), var(--gradient-end)) border-box;
  color: var(--text-color);
  padding: var(--button-padding);
  border-radius: var(--pill-border-radius);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: var(--thin-border-width) solid transparent;
  transition: transform var(--scale-duration) ease, box-shadow var(--scale-duration) ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  min-width: 140px;
  margin: 5px;
  position: relative;
  z-index: 3;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}

/* Hover Effects */
@media (hover: hover) {
  .decision-tree-option:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(var(--gradient-end), 0.2);
  }
}

/* Combined Result Styles */
.decision-tree-option.guided-result,
.decision-tree-option.personalized-result {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  padding: var(--button-padding);
  border-radius: var(--pill-border-radius);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  min-width: 140px;
  margin: 5px;
  position: relative;
  z-index: 3;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  border: var(--thin-border-width) solid transparent;
  transition: transform var(--scale-duration) ease, box-shadow var(--scale-duration) ease;
}

/* Specific Styles for Guided and Personalized Buttons */
.decision-tree-option.guided-result {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, #0cffdf, #0a9e8b) border-box;
  box-shadow: 0 0 20px #0cffdf;
}

.decision-tree-option.guided-result:hover {
  box-shadow: 0 0 20px #0cffdf;
  transform: scale(1.1);
}

.decision-tree-option.personalized-result {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, #520250, #2d0329) border-box;
  box-shadow: 0 0 20px #520250;
}

.decision-tree-option.personalized-result:hover {
  box-shadow: 0 0 20px #520250;
  transform: scale(1.1);
}

/* Selected Options */
.selected-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.selected-option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--main-bg-color);
  border-radius: var(--pill-border-radius);
  border: var(--thin-border-width) solid transparent;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, var(--gradient-start), var(--gradient-end)) border-box;
}

.main-category-selected {
  border-width: var(--border-gradient-width);
}

.sub-category-selected {
  border-width: var(--thin-border-width);
}

/* Icon Controls Group */
.icon-group {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  z-index: 5; /* Ensure it stays on top */
}

/* Individual Icon Container */
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%); /* Add subtle shadow */
}

.icon-container:hover {
  transform: scale(1.1); /* Hover effect */
  background-color: rgb(255 255 255 / 10%); /* Add hover background */
}

/* Personalization Step */
.personalization-step {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.personalization-step h3 {
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
}

.personalization-step textarea {
  width: 100%;
  min-height: 150px;
  background-color: var(--neomorphic-light);
  color: var(--text-color);
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  resize: vertical;
  appearance: none;
}

/* Wave Button */
.wave-btn {
  background-color: var(--guided-color);
  color: var(--text-color);
  border: none;
  border-radius: var(--pill-border-radius);
  padding: var(--button-padding);
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.wave-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgb(108 78 242 / 50%);
}

.visualization-main { --gradient-start: #ff4e50; --gradient-end: #f9d423; }
.visualization-sub-1 { --gradient-start: #ff7e5f; --gradient-end: #feb47b; }
.visualization-sub-2 { --gradient-start: #fdc830; --gradient-end: #f37335; }
.visualization-result { --gradient-start: #ff4e50; --gradient-end: #f9d423; }

.mindfulness-main { --gradient-start: #12c2e9; --gradient-end: #c471ed; }
.mindfulness-sub-1 { --gradient-start: #89f7fe; --gradient-end: #66a6ff; }
.mindfulness-sub-2 { --gradient-start: #12c2e9; --gradient-end: #c471ed; }
.mindfulness-result { --gradient-start: #12c2e9; --gradient-end: #c471ed; }

.sleep-main { --gradient-start: #43cea2; --gradient-end: #185a9d; }
.sleep-sub-1 { --gradient-start: #76b852; --gradient-end: #8dc26f; }
.sleep-sub-2 { --gradient-start: #a1ffce; --gradient-end: #faffd1; }
.sleep-result { --gradient-start: #43cea2; --gradient-end: #185a9d; }

.manifestation-main { --gradient-start: #f64f59; --gradient-end: #c471ed; }
.manifestation-sub-1 { --gradient-start: #e1eec3; --gradient-end: #f05053; }
.manifestation-sub-2 { --gradient-start: #f7797d; --gradient-end: #FBD786; }
.manifestation-result { --gradient-start: #f64f59; --gradient-end: #c471ed; }

.breath-main { --gradient-start: #a18cd1; --gradient-end: #fbc2eb; }
.breath-sub-1 { --gradient-start: #bdc3c7; --gradient-end: #2c3e50; }
.breath-sub-2 { --gradient-start: #43e97b; --gradient-end: #38f9d7; }
.breath-result { --gradient-start: #a18cd1; --gradient-end: #fbc2eb; }

.affirmations-main { --gradient-start: #f093fb; --gradient-end: #f5576c; }
.affirmations-sub-1 { --gradient-start: #f7971e; --gradient-end: #ffd200; }
.affirmations-sub-2 { --gradient-start: #30cfd0; --gradient-end: #330867; }
.affirmations-result { --gradient-start: #f093fb; --gradient-end: #f5576c; }

.concentration-main { --gradient-start: #30cfd0; --gradient-end: #330867; }
.concentration-sub-1 { --gradient-start: #e55d87; --gradient-end: #5fc3e4; }
.concentration-sub-2 { --gradient-start: #ff512f; --gradient-end: #dd2476; }
.concentration-result { --gradient-start: #30cfd0; --gradient-end: #330867; }

.body_scan-main { --gradient-start: #48c6ef; --gradient-end: #6f86d6; }
.body_scan-sub-1 { --gradient-start: #92fe9d; --gradient-end: #00c9ff; }
.body_scan-sub-2 { --gradient-start: #c471f5; --gradient-end: #fa71cd; }
.body_scan-result { --gradient-start: #48c6ef; --gradient-end: #6f86d6; }

.unguided-main { --gradient-start: #d558c8; --gradient-end: #24d292; }
.unguided-result { --gradient-start: #d558c8; --gradient-end: #24d292; }

/* Specific Styles for Guided and Personalized Results */
.decision-tree-option.visualization-result,
.decision-tree-option.mindfulness-result,
.decision-tree-option.sleep-result,
.decision-tree-option.manifestation-result,
.decision-tree-option.breath-result,
.decision-tree-option.affirmations-result,
.decision-tree-option.concentration-result,
.decision-tree-option.body_scan-result {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, var(--gradient-start), var(--gradient-end)) border-box;
}

/* Guided Result */
.decision-tree-option.guided-result,
[class*="-result"].guided-result {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, #0cffdf, #0a9e8b) border-box;
  box-shadow: 0 0 20px #0cffdf;
}

.decision-tree-option.guided-result:hover,
[class*="-result"].guided-result:hover {
  box-shadow: 0 0 20px #0cffdf;
  transform: scale(1.1);
}

/* Personalized Result */
.decision-tree-option.personalized-result,
[class*="-result"].personalized-result {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box,
              linear-gradient(145deg, #520250, #2d0329) border-box;
  box-shadow: 0 0 20px #520250;
}

.decision-tree-option.personalized-result:hover,
[class*="-result"].personalized-result:hover {
  box-shadow: 0 0 20px #520250;
  transform: scale(1.1);
}


/* Animations */
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(50px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInScaleUp {
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-exit {
  animation: fadeOut 0.5s ease forwards;
}

.fade-enter {
  animation: fadeInUp 0.5s ease forwards;
}

/* Loading State */
.loading-state {
  color: var(--text-color);
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
}

/* Mobile Styles */
@media (width <= 768px) {
  .decision-tree-container {
    margin: 1em 0;
    padding: 20px;
  }

  .decision-tree-options {
    gap: 10px;
    padding: 0 10px;
  }

  .decision-tree-option {
    font-size: 0.85rem !important;
    padding: 8px 18px !important;
    min-width: 120px !important;
  }

  .icon-container {
    width: 30px;
    height: 30px;
  }

  .decision-tree-prompt {
    font-size: 1.2rem;
  }
}

@media (width <= 480px) {
  .decision-tree-container {
    padding: 10px;
    margin: 0.5em 0;
  }

  .decision-tree-options {
    gap: 8px;
  }

  .decision-tree-option {
    font-size: 0.75rem !important;
    padding: 6px 12px !important;
    min-width: 100px !important;
  }
}

/* iOS Specific Fixes */
@supports (-webkit-touch-callout: none) {
  .decision-tree-container,
  .decision-tree-node,
  .decision-tree-options,
  .decision-tree-option,
  .icon-container {
    transform: translate3d(0, 0, 0) !important;
    backface-visibility: hidden !important;
  }
}