.notifications-page {
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  background: var(--secondary-color);
  min-height: 100vh;
}

.filter-group {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.filter-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background: rgb(255 255 255 / 5%);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.filter-btn:hover {
  background: rgb(255 255 255 / 10%);
}

.filter-btn.active {
  background: var(--primary);
  color: #000;
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notification-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 20px;
  background: #1a1a1a;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgb(255 255 255 / 10%);
}

.notification-item:hover {
  transform: translateX(8px);
  background: #202020;
}

.notification-item.read {
  opacity: 0.7;
  background: #151515;
}

.notification-icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 12px;
  background: rgb(255 255 255 / 3%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-icon.success { 
  color: #81c784;
  background: rgb(129 199 132 / 10%);
}

.notification-icon.error { 
  color: #e57373;
  background: rgb(229 115 115 / 10%);
}

.notification-icon.info { 
  color: #64b5f6;
  background: rgb(100 181 246 / 10%);
}

.notification-icon.reminder { 
  color: #ffb74d;
  background: rgb(255 183 77 / 10%);
}

.notification-content {
  flex: 1;
}

.notification-content h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--text-color);
}

.notification-content p {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 8px;
  opacity: 0.8;
}

.notification-content time {
  font-size: 12px;
  color: var(--text-secondary);
  opacity: 0.6;
  display: block;
}

.notification-actions {
  display: flex;
  gap: 8px;
}

.action-btn {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: rgb(255 255 255 / 3%);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:hover {
  background: rgb(255 255 255 / 10%);
}

.action-btn.mark-read {
  color: var(--primary);
  background: rgb(187 134 252 / 10%);
}

.action-btn.mark-read:hover {
  background: rgb(187 134 252 / 20%);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  color: var(--text-secondary);
  gap: 16px;
  text-align: center;
  opacity: 0.7;
}

.empty-state svg {
  opacity: 0.5;
}

@media (width <= 768px) {
  .notifications-page {
    padding: 16px;
  }

  .filter-group {
    justify-content: center;
    flex-wrap: wrap;
  }

  .notification-item {
    padding: 16px;
  }

  .notification-content h3 {
    font-size: 15px;
  }

  .notification-content p {
    font-size: 13px;
  }
}