.wave-btn {
  border-radius: 50px;
  background: linear-gradient(145deg, #1a1a1a, #080808);
  box-shadow: 8px 8px 16px #000, -8px -8px 16px #2a2a2a;
  border: none;
  font-size: 1rem;
  padding: 0.7em 1.5em;
  cursor: pointer;
  position: relative;
  color: #e0e0e0;
  margin: 2em;
  transition: all 0.3s ease;
  overflow: hidden;
  text-align: center;
}

.wave-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgb(255 255 255 / 10%), transparent);
  transition: 0.5s;
  opacity: 0;
}

.wave-btn:hover:not(:disabled),
.wave-btn.script-generated:not(:disabled) {
  color: #fff;
  box-shadow: 0 0 20px 10px rgb(146 68 255 / 60%);
}

.wave-btn:hover::before,
.wave-btn.script-generated::before {
  opacity: 1;
  animation: wave 1.5s infinite;
}

@keyframes wave {
  0% { transform: translateX(-100%) skew(10deg); }
  100% { transform: translateX(100%) skew(10deg); }
}

.wave-btn.creating-session {
  background-color: #393BB2;
  box-shadow: 0 0 10px #E2CBFF, 0 0 20px #393BB2, 0 0 30px #E2CBFF;
  animation: creatingPulse 2s infinite;
  color: #fff;
}

@keyframes creatingPulse {
  0%, 100% { box-shadow: 0 0 10px #E2CBFF, 0 0 20px #393BB2, 0 0 30px #E2CBFF; }
  50% { box-shadow: 0 0 20px #E2CBFF, 0 0 30px #393BB2, 0 0 40px #E2CBFF; }
}

.wave-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}