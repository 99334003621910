/* Base Panel Styles */
.notifications-panel {
    position: fixed;
    top: 64px;
    right: 16px;
    width: 320px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    background: rgb(18 18 18 / 85%);
    backdrop-filter: blur(20px);
    border: 1px solid rgb(255 255 255 / 10%);
    box-shadow: 0 8px 32px rgb(0 0 0 / 40%);
    border-radius: 16px;
    padding: 20px;
    z-index: 9999;
    animation: slideIn 0.3s ease-out;
}

/* Header Section */
.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.notifications-header h3 {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    margin: 0;
}

.header-actions {
    display: flex;
    gap: 8px;
    align-items: center;
}

/* Button Styles */
.clear-all-btn,
.close-btn {
    background: rgb(255 255 255 / 5%);
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    color: var(--text-secondary);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
}

.clear-all-btn:hover,
.close-btn:hover {
    background: var(--hover-overlay);
    color: var(--primary);
    transform: translateY(-1px);
}

/* Notification Items */
.notification-content {
    display: flex;
    align-items: flex-start;
    background: rgb(255 255 255 / 3%);
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 12px;
    border: 1px solid rgb(255 255 255 / 5%);
    transition: all 0.3s ease;
}

.notification-content:hover {
    background: rgb(255 255 255 / 5%);
    border-color: var(--primary);
    transform: translateY(-2px);
}

.notification-icon {
    margin-right: 16px;
    color: var(--primary);
    padding: 8px;
    background: rgb(255 255 255 / 5%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-text {
    flex: 1;
    min-width: 0;
}

.notification-text h4 {
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 4px;
}

.notification-text p {
    color: var(--text-secondary);
    font-size: 13px;
    line-height: 1.5;
    margin: 0;
}

.notification-text time {
    display: block;
    font-size: 12px;
    color: var(--text-secondary);
    opacity: 0.7;
    margin-top: 6px;
}

/* Empty State */
.empty-state {
    text-align: center;
    padding: 32px 16px;
    color: var(--text-secondary);
}

.empty-state svg {
    color: var(--primary);
    opacity: 0.5;
    margin-bottom: 12px;
}

.empty-state p {
    font-size: 14px;
    margin: 0;
    color: var(--text-secondary);
}

/* Scrollbar Styles */
.notifications-panel::-webkit-scrollbar {
    width: 6px;
}

.notifications-panel::-webkit-scrollbar-track {
    background: transparent;
}

.notifications-panel::-webkit-scrollbar-thumb {
    background: var(--hover-overlay);
    border-radius: 3px;
}

/* Animation */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (width <= 768px) {
    .notifications-panel {
        width: auto;
        left: 16px;
        right: 16px;
        max-width: none;
    }
}

@media (width <= 480px) {
    .notifications-panel {
        left: 8px;
        right: 8px;
        top: 60px;
        padding: 16px;
    }
}

@media (height <= 600px) {
    .notifications-panel {
        max-height: calc(100vh - 72px);
    }
}