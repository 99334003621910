/* styles/base/_variables.css */
:root {
  /* Colors */
  --bg-body-dark: #000000;
  --bg-secondary-dark: #000000;
  --text-color-dark: #FFFFFF;
  --accent-color-dark: #BB86FC;
  --bg-body-light: #FFFFFF;
  --bg-secondary-light: #E0E0E0;
  --text-color-light: #000000;
  --accent-color-light: #6200EE;
  
  /* Gradients */
  --bg-main-light: linear-gradient(135deg, #FDFCFD 0%, #E2EBF0 100%);
  --bg-main-dark: linear-gradient(135deg, #293149 0%, #180F22 100%);
  
  /* Glow Effects */
  --glow-color: rgb(217, 176, 255);
  --glow-spread-color: rgba(191, 123, 255, 0.781);
  --enhanced-glow-color: rgb(231, 206, 255);
  --btn-color: rgb(100, 61, 136);
  
  /* Animation Settings */
  --typing-speed: 30ms;
  --text-bg-opacity: 0.5;
  --text-blur: 10px;
  --transition-speed: 0.3s;
  
  /* Layout */
  --container-width: 1200px;
  --spacing-large: 60px;
  --spacing-medium: 30px;
  --spacing-small: 15px;
}



/* styles/base/_base.css */
@font-face {
  font-family: 'AeonikRegular';
  src: url('../../assets/Aeonik-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Selecta-regular';
  src: url('../../assets/Selecta-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--bg-body-dark);
  color: var(--text-color-dark);
  font-family: 'AeonikRegular', sans-serif;
  scroll-behavior: smooth;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Selecta-regular', sans-serif;
  letter-spacing: 0.5px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.75);
  margin: 0 0 var(--spacing-medium);
}

p {
  margin: 0 0 var(--spacing-small);
}

/* Core section styles */
.section {
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  background: transparent;
  isolation: isolate;

}

/* Add new class to dim entire site */
.site-dimmed {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 100009; /* Just below mood selector */
  pointer-events: none;
 }
 

.content {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: var(--spacing-medium);
}

/* styles/base/_animations.css */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typeText {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes fadeInContainer {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseGlow {
  0%, 100% {
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(187, 134, 252, 0.8);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
} 

.landing-section::before,
.how-it-works-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 10% 50%, /* Position light source */
    rgba(187, 134, 252, 0.15), /* Subtle light */
    rgba(0, 0, 0, 0) 80%
  );
  pointer-events: none; /* Prevent interaction interference */
  z-index: -1; /* Behind all content */
}

.landing-section {
  position: relative; /* Ensure proper stacking */
}

.how-it-works-section {
  position: relative; /* Ensure proper stacking */
}

.landing-page-container {
  background-color: black;
  color: var(--text-color-dark);
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.landing-section {
  padding-top: 0; /* Remove default padding */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content {
  padding-top: 80px; /* Add padding to account for fixed header */
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

/* Ensure sections after landing account for header when scrolling */
section:not(.landing-section) {
  scroll-margin-top: 80px;
}

.landing-section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-large) var(--spacing-medium);
  gap: 2rem;
  transform: translateY(5vh); /* Shift content slightly down for more space above */
}

/* Landing section specific styles */
#landing {
  background: linear-gradient(
    180deg,
    #050508 0%,
    #080818 25%,
    #0a0a20 50%,
    #0c0c25 75%,
    #0e0e2a 100%
  );
  z-index: 1;
}

.landing-section h2 {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  line-height: 1.4;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  will-change: transform;
  letter-spacing: -0.02em;
}

.word {
  display: inline-block;
  transform-origin: center center;
  will-change: transform, opacity;
  margin-right: 0.5rem; /* Add space between words */
  white-space: nowrap;
}



.background-layer {
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  will-change: transform;
    z-index: -1;
    mix-blend-mode: normal;
  }


/* GSAP-specific helper class */
.gsap-init {
  visibility: hidden;
}


.logo-container {
  margin-bottom: 2rem;
}

.logo-container img.logo {
  max-width: 220px; /* Slightly smaller logo */
  height: auto;
  display: block;
  margin: 0 auto;
  transition: transform var(--transition-speed) ease;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.1));
  will-change: transform, opacity;
}
.logo-container img.logo:hover {
  transform: scale(1.05);
}

/* How it works section styles */
.how-it-works-section {
  background: linear-gradient(180deg,
    #0E0E2A 0%,
    #0A0A20 15%,
    #080818 25%,
    #050508 50%,
    #0b0b12 75%,
    #020203 100%
  );
  z-index: 2;
  position: relative;
}


.background-layer, .sheet-bg {
  z-index: 0; /* Ensure it's behind the content */
}

/* Remove sheet background */
.sheet-bg {
  display: none;
}

.how-it-works-section .content {
  position: relative;
  z-index: 3; /* Above devices and background */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 20px;
}

.pulsating-ripple-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  --pulse-scale: 1;
  --pulse-opacity: 0.8;
}

.ripple {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 2px solid rgba(147, 112, 219, 0.7);
  border-radius: 50%;
  background: radial-gradient(circle, rgba(147, 112, 219, 0.2), transparent);
  pointer-events: none;
  transform-origin: center center;
  will-change: transform, opacity;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pulsating-ripple-container::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background: radial-gradient(circle, rgba(147, 112, 219, 1), rgba(75, 0, 130, 0));
  border-radius: 50%;
  box-shadow: 
    0 0 20px rgba(147, 112, 219, 0.8),
    0 0 40px rgba(75, 0, 130, 0.6);
  transform-origin: center center;
  transform: scale(var(--pulse-scale));
  opacity: var(--pulse-opacity);
  will-change: transform, opacity;
}

.intent-text {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Shifted down */
}

.intent-text .text-wrapper {
  display: inline-block; /* Required for letter/word animations */
  overflow: hidden; /* Hide content for sliding in */
  white-space: nowrap;
  border-right: 2px solid rgba(147, 112, 219, 1); /* Cursor effect */
  animation: blink-cursor 0.5s step-end infinite; /* Blinking cursor effect */
}

.text-input-container {
  width: 50%;
  max-width: 700px;
  margin: 20px auto;
  padding: 1.5rem 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;
  overflow: hidden;
}

#scrollText {
  font-size: 1.4em;
  color: rgba(255, 255, 255, 0.9);
  white-space: pre-wrap;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInText 0.5s ease forwards 0.3s;
  display: inline-block;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: #bb86fc; /* Adjust color */
  animation: blink 0.7s steps(2, start) infinite;
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.1%, 100% {
    opacity: 0;
  }
}

/* Devices */
.device {
  position: absolute;
  z-index: 3;
  transition: transform 0.5s ease;
  will-change: transform;
  opacity: 1;
}

.iphone-container {
  right: 5%; /* Moved further to the right by increasing the right value */
  top: 30%; /* Positioned higher up */
  transform: translateY(-50%) rotate(5deg); /* Rotated 30 degrees to the right */
  width: 600px;
}

.airpods-container {
  left: 10%; /* Position slightly to the left */
  top: 40%; /* Positioned higher to start */
  transform: translateY(-50%) rotate(-5deg); /* Slight rotation */
  width: 500px; /* Size remains unchanged */
}

.iphone-img, .airpods-img {
  max-width: 100%;
  display: block;
  will-change: transform, opacity;
}

.hover-float:hover {
  transform: translateY(-10px) scale(1.1);
  transition: all 0.3s ease;
}


#benefits {
  background: transparent !important;
  position: relative;
  z-index: 3;
  height: 100vh;
}

#benefits .content {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 20px;
}

#benefits .content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 16px;
  line-height: 1.3;
}

#benefits .content p {
  font-size: 1.25rem;
  color: #8e8e8e;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}


#spline-benefits-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.spline-container canvas {
  width: 100%;
  height: 100%;
  background: transparent !important;
  mix-blend-mode: normal !important;
}

#benefits .star-layer {
  z-index: 0;
}

.content {
  position: relative;
  z-index: 2;
  padding-top: 20vh;
}


/* Remove loading overlay background */
.loading-overlay {
  background: transparent !important;
    mix-blend-mode: screen;

}


.spinner {
  mix-blend-mode: screen;
}


@media (max-width: 768px) {
  #benefits .content h2 {
    font-size: 2.5rem; /* Adjust for smaller screens */
  }

  #benefits .content p {
    font-size: 1rem; /* Smaller body text */
    padding: 0 20px; /* Add padding for narrow screens */
  }
}

@media (max-width: 480px) {
  #benefits .content h2 {
    font-size: 2rem; /* Further reduction for very small screens */
  }

  #benefits .content p {
    font-size: 0.9rem; /* Reduce body text size */
  }
}

/* Features Section Container */
#features {
  background: linear-gradient(180deg, #121216 0%, #1a1a26 100%);
  min-height: 100vh;
  padding: 140px 20px; /* Aligns with other sections */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  overflow: hidden;
}

/* Text Content */
.text-style {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  color: #f4f4f9;
}

.text-style h2 {
  font-size: 2.8rem;
  font-weight: 600;
  color: #f5f5f7;
  text-align: center;
  letter-spacing: -0.02em;

 }
 

 @media (max-width: 768px) {
  .text-style h2 {
    font-size: 2rem;
  }
 }

/* Audio Player Section */
.audio-player-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 16px;
  background: radial-gradient(circle, #2a2a38 0%, #121216 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.audio-player-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(187, 134, 252, 0.2), transparent);
  opacity: 0.1;
  animation: subtle-glow 6s infinite;
  pointer-events: none;
}

@keyframes subtle-glow {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.audio-player-container audio {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.audio-player-container p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Features Grid */
.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 0 20px;
  max-width: 1200px;
}

.feature-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.feature-item:hover {
  transform: translateY(-8px) scale(1.05);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.08);
}

.feature-icon {
  width: 32px;
  height: 32px;
  color: #ffffff;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.3));
}

/* Feature Icon Styling */
.feature-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bb86fc, #3700b3);
  box-shadow: 0 4px 10px rgba(187, 134, 252, 0.4);
  margin-bottom: 1rem;
}

.feature-text h3 {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feature-text p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  #features {
    padding: 100px 20px;
  }

  .text-style h2 {
    font-size: 2.5rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}





.sticky-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.sticky-button img {
  width: 50px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.sticky-button img:hover {
  opacity: 1;
}

/* Button Styles */
.button {
  display: inline-block;
  background-color: transparent;
  color: white;
  padding: 15px 30px;
  border-radius: 20px;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
}

/* Download Section Core Styles */
#download {
  text-align: center;
  background: linear-gradient(180deg, #080818 0%, #050508 100%);
  min-height: 100vh;
  padding: 100px 20px;
  position: relative;
  isolation: isolate;
}

#download .content {
  position: relative;
  z-index: 2;
  opacity: 1;
}

#download .dlicon-container {
  width: clamp(200px, 20vw, 300px);
  height: clamp(200px, 20vw, 300px);
  margin: 0 auto 3rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Initial state for animation */
  transform: translateY(50px) scale(0.8); /* Initial state for animation */
}

#download .app-icon {
  width: 90%;
  height: 90%;
  object-fit: contain;
  filter: drop-shadow(0 10px 20px rgba(187, 134, 252, 0.3));
}

/* Store Buttons Container */
#download .store-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 3rem;
  padding: 0 20px;
  opacity: 0; /* Initial state for animation */
  transform: translateY(30px); /* Initial state for animation */
  visibility: visible; /* Ensure visibility */
  pointer-events: auto; /* Ensure clickability */
}

#download .store-link {
  display: block;
  transition: transform 0.3s ease;
  opacity: 1; /* Full opacity for the links themselves */
}

#download .store-link img {
  width: 200px;
  height: auto;
  opacity: 1; /* Full opacity for the images */
}

#download .store-link:hover {
  transform: scale(1.05);
}

/* Title */
#download h2 {
  color: var(--text-primary);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  opacity: 0; /* Initial state for animation */
  transform: translateY(30px); /* Initial state for animation */
}

@media (max-width: 768px) {
  #download .dlicon-container {
    width: 200px;
    height: 200px;
  }
  
  #download .store-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  #download .store-link img {
    width: 180px;
  }
}

@media (max-width: 480px) {
  #download .dlicon-container {
    width: 150px;
    height: 150px;
  }
  
  #download .store-link img {
    width: 160px;
  }
}



/* Glow Effect */
.button.glowing-effect {
  border: 0.25em solid var(--glow-color);
  padding: 1em 3em;
  color: var(--glow-color);
  font-size: 15px;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 
    0 0 1em 0.25em var(--glow-color),
    0 0 4em 1em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
  text-shadow: 0 0 0.5em var(--glow-color);
  position: relative;
}

.button.glowing-effect::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: 0.7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

.button.glowing-effect:hover {
  color: var(--btn-color);
  background-color: var(--glow-color);
  box-shadow: 
    0 0 1em 0.25em var(--glow-color),
    0 0 4em 2em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
}



/* Media Queries */
@media (max-width: 1400px) {
  .text-input-container {
    width: 60%;
  }
  .iphone-container {
    width: 450px;
  }
  .airpods-container {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .text-input-container {
    width: 90%;
  }
  .device {
    display: none;
  }
  
  .sticky-button img {
    width: 40px;
  }
}

@media (max-width: 480px) {
  #scrollText {
    font-size: 1em;
  }

  h2 {
    font-size: 1.3em;
  }

  .iphone-img {
    width: 50%;
    left: 20%;
  }

  .headphone {
    width: 40%;
    left: -30%;
  }

  .sticky-button {
    bottom: 10px;
  }

  .sticky-button img {
    width: 25px;
  }

  .prompt-container {
    padding: var(--spacing-small);
  }


}

/* Section Container */
.cognitive-section {
  min-height: 120vh; /* Slightly taller section for negative space */
  width: 100%;
  padding: 100px 40px; /* Added more vertical padding */
  position: relative;
  background: #000000;
  overflow: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  mask-mode: screen;
}


.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 16px;
  margin-top: -55px;
}

.section-subtitle {
  font-size: 1.25rem;
  color: #8e8e8e;
  text-align: center;
  margin-bottom: 40px;
}

.cognitive-content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
  width: 100%;
}

.fact {
  text-align: center;
  max-width: 300px;
}

.fact-media {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.fact-gif {
  width: 50%;
  height: auto;
  border-radius: 16px;
}

/* Base styling for brain GIF */
.fact-gif2 {
  transform: translateY(35px);
  margin-bottom: -25px;
  opacity: 0;
  will-change: transform, opacity;
  transition: opacity 0.6s ease-out;
}

/* Smooth floating animation */
@keyframes brainFloat {
  0%, 100% {
    transform: translateY(35px) translateZ(0);
  }
  50% {
    transform: translateY(25px) translateZ(0);
  }
}

/* Animation class - keeping it simple */
.fact-gif2.animate {
  animation: brainFloat 6s ease-in-out infinite;
  opacity: 1;
}

/* Remove container glow and handle hover effect */
.gradient-overlay {
  position: relative;
  background: transparent;
  border-radius: 16px;
}

/* Optional: Add subtle hover effect */
@media (hover: hover) {
  .gradient-overlay:hover .fact-gif2 {
    animation-duration: 4s;
  }
}

.spline2 {
  width: 50%;
  height: auto;
  border-radius: 16px;
}


.spline2-container {
  width: 100%;
  max-width: 1200px;
  height: 400px;
  margin: -20px auto 0 auto; /* Move up by 20px */
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  
}

.gradient-line {
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, #bb86fc, #3700b3);
  margin-top: 8px;
}

.fact-icon {
  margin: 10px auto;
}

.fact-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
  margin: 10px 0;
}

.fact-description {
  font-size: 1rem;
  color: #8e8e8e;
  line-height: 1.5;
}

.cta-button {
  margin: 40px auto;
  padding: 15px 40px;
  border: 2px solid transparent;
  background: linear-gradient(135deg, #bb86fc, #8e61d4);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, #8e61d4, #bb86fc);
  z-index: -1;
  transform: scale(0);
  border-radius: 50px;
  transition: transform 0.3s ease;
}

.cta-button:hover::before {
  transform: scale(1.2);
}

.cta-button:hover {
  color: #000;
  transform: translateY(-3px);
  box-shadow: 0 10px 30px rgba(187, 134, 252, 0.5);
}

.cta-icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.cta-button:hover .cta-icon {
  transform: translateX(5px);
}

/* Responsive */
@media (max-width: 768px) {
  .cognitive-content {
    padding: 0 20px;
  }
  
  .fact {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
}

/* Animation for Gradient */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



/* Action Button */
.download-pause {
  margin: 20px auto;
  padding: 15px 30px;
  background: linear-gradient(135deg, #BB86FC 0%, #8E61D4 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
}

.download-pause:hover {
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 10px 20px rgba(187, 134, 252, 0.2);
}

/* Utility Classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.blur-backdrop {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.preserve-3d {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.no-scroll {
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  .aurora-effect,
  .aurora-bg {
    animation: none !important;
  }
}

@media print {
  .sticky-button,
  .download-icons,
  .audio-player-container {
    display: none;
  }

  body {
    background: white;
    color: rgba(0, 0, 0, 0);
  }

  .section {
    min-height: auto;
    page-break-inside: avoid;
  }

  .section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(26, 26, 46, 0.5), rgba(26, 26, 46, 0));
    z-index: -1;
    transition: opacity 1s ease;
  }

  .neural-network-bg,
  .aurora-effect,
  .aurora-bg {
    display: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .text-glow,
  .gradient-text {
    text-shadow: none;
    background: none;
    color: black;
  }
}



/* Star field below aurora */
.star-field-overlay {
  position: fixed; /* Change to fixed to match aurora */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0; /* From -2 to 0 */
  background: none;
  pointer-events: none;
}

.star-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  z-index: -2;
}

.star {
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  will-change: opacity;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.8);
  filter: blur(0.5px);
  z-index: -2;
  mix-blend-mode: screen;
}

/* Prevent background conflicts */
.background-layer {
  mix-blend-mode: screen;
  pointer-events: none;
  z-index: 0;
}

/* Remove conflicting background image */
.background-layer.parallax-bg {
  display: none;
}

.gradient-wrapper {
  position: relative;
  overflow: hidden; /* Ensures the gradient stays within bounds */
}

.gradient-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%; /* Extend the gradient to cover both sections */
  background: radial-gradient(
    circle at 10% 50%, /* Position light source */
    rgba(187, 134, 252, 0.15), /* Subtle light */
    rgba(0, 0, 0, 0) 80%
  );
  pointer-events: none; /* Prevent interaction interference */
  z-index: -1; /* Behind all content */
}

