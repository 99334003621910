/* calstyle.css */

/* Root Variables */
:root {
  /* Core Colors */
  --bg-body-dark: #121212;
  --bg-secondary-dark: #252525;
  --text-color-dark: #FFF;
  --accent-color: #BB86FC;
  --accent-rgb: 187, 134, 252;
  --error-color: rgb(255 69 58);
  
  /* Gradients */
  --bg-main-gradient: linear-gradient(135deg, #293149 0%, #180F22 100%);
  
  /* Opacities and Overlays */
  --surface-opacity-1: rgb(255 255 255 / 5%);
  --surface-opacity-2: rgb(255 255 255 / 10%);
  --surface-opacity-3: rgb(255 255 255 / 15%);
  --overlay-dark: rgb(0 0 0 / 70%);
  
  /* Effects */
  --blur-effect: blur(8px);
  --shadow-1: 0 4px 12px rgb(0 0 0 / 10%);
  --shadow-2: 0 8px 24px rgb(0 0 0 / 20%);
  --shadow-3: 0 15px 40px rgb(0 0 0 / 30%);
  
  /* Aurora Colors */
  --aurora-1: rgb(187 134 252 / 30%);
  --aurora-2: rgb(255 0 255 / 20%);
  --aurora-3: rgb(0 255 255 / 20%);
  
  /* Layout */
  --border-radius: 12px;
  --calendar-width: 600px;
  --mood-selector-width: 320px;
  --calendar-cell-size: 60px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 20px;
  --spacing-lg: 30px;
  
  /* Transitions */
  --transition-fast: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-medium: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  --ease-bounce: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Base Styles */
body {
  margin: 0;
  padding: 0;
  color: var(--text-color-dark);
}

#mindfulness-ai {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: transparent !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
  isolation: isolate;
  overflow: visible;
  padding: 100px 40px; /* Match padding from other sections */
  z-index: 1;
}

#mindfulness-ai .section-title {
  font-size: 2.5rem; /* Match Cognitive Section */
  font-weight: bold;
  color: var(--text-color-dark);
  text-align: center;
  margin-bottom: var(--spacing-lg);
  margin-top: -55px; /* Optional: Align positioning */
  line-height: 1.3;
}

.aurora-bg {
  position: fixed; /* Change back to fixed for smooth scrolling */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: 0.8;
  background-image: radial-gradient(
    circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    var(--aurora-1),
    var(--aurora-2),
    var(--aurora-3),
    transparent 70%
  );
  mix-blend-mode: screen;
  filter: blur(80px) contrast(120%);
  transform-origin: center;
  animation: auroraShift 15s infinite ease-in-out;
  z-index: 1; /* From -1 to 1 */
}

.aurora-bg::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgb(147 51 234 / 30%),
    rgb(79 70 229 / 20%) 30%,
    rgb(67 56 202 / 15%) 50%,
    transparent 70%
  );
  mix-blend-mode: screen;
  filter: blur(40px);
  animation: auroraWaves 20s infinite;
  z-index: -1;
}

/* Update z-indices for all relevant sections */
#benefits {
  position: relative;
  z-index: 2;
}

#cognitive {
  position: relative;
  z-index: 2;
}

/* Ensure content stays above aurora */
.mindfulness-features,
.calendar-container,
.cognitive-content,
.spline-container,
.title-container {
  position: relative;
  z-index: 2;
}

/* Modify animations for smoother transition */
@keyframes auroraShift {
  0%, 100% {
    filter: blur(60px) contrast(130%) hue-rotate(0deg);
    transform: scale(1) translateY(0);
  }

  50% {
    filter: blur(80px) contrast(160%) hue-rotate(180deg);
    transform: scale(1.1) translateY(-10%);
  }
}

@keyframes auroraWaves {
  0%, 100% {
    opacity: 0.6;
    transform: scale(1) rotate(0deg) translateY(0);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.2) rotate(180deg) translateY(-5%);
  }
}

@media (width <= 768px) {
  #mindfulness-ai .section-title {
    font-size: 2rem; /* Scale down for smaller screens */
  }

  #mindfulness-ai .calendar-container {
    max-width: 100%;
    padding: 0 20px; /* Add padding for smaller screens */
  }
}

@media (width <= 480px) {
  #mindfulness-ai .section-title {
    font-size: 1.8rem; /* Further reduce title size */
  }

  #mindfulness-ai .calendar-container {
    padding: 0 10px;
  }
}

/* Add fade-out effect at the bottom */
.aurora-bg::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--bg-body-dark)
  );
  pointer-events: none;
}

/* Calendar Container */
.calendar-container {
  position: relative;
  width: 100%;
  max-width: var(--calendar-width);
  margin: 0 auto;
  perspective: 2000px;
  z-index: 3;
}

/* Calendar Header Text */
.calendar-header-text {
  color: var(--text-color-dark);
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
  animation-delay: 0.2s;
}

/* Main Calendar */
.calendar {
  width: 100%;
  padding: var(--spacing-lg);
  background: var(--bg-main-gradient);
  color: var(--text-color-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-3);
  transform-style: preserve-3d;
  transition: transform var(--transition-medium), box-shadow var(--transition-medium);
  user-select: none;
  position: relative;
}

/* Calendar Header */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.month-picker {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

#year {
  font-size: 20px;
  margin-left: var(--spacing-xs);
  opacity: 0.8;
}

.year-change {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: var(--text-color-dark);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius);
  border: none;
  background: transparent;
  transition: all var(--transition-fast);
}

.year-change:not(:disabled):hover {
  color: var(--accent-color);
  background: var(--surface-opacity-1);
}

.year-change:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.calendar-day .mood-emoji {
  filter: drop-shadow(0 2px 4px rgb(0 0 0 / 20%));
}

/* Calendar Grid */
.calendar-week-day,
.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: var(--spacing-xs);
}

/* Week Day Labels */
.calendar-week-day {
  margin-bottom: var(--spacing-md);
}

.calendar-week-day div {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: var(--text-color-dark);
  padding: var(--spacing-xs);
  opacity: 1;
}

/* Calendar Days */
.calendar-day {
  position: relative;
  aspect-ratio: 1;
  min-height: var(--calendar-cell-size);
  padding: var(--spacing-xs);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
  cursor: default;
  background: rgb(255 255 255 / 5%);
  transition: all 0.3s ease;
}

.calendar-day:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgb(0 0 0 / 60%);
  background: rgb(255 255 255 / 8%);
 }

/* Root Variables and existing styles remain the same */

/* Update Calendar Day States */
.calendar-day {
  position: relative;
  aspect-ratio: 1;
  min-height: var(--calendar-cell-size);
  padding: var(--spacing-xs);
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
  cursor: default;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Past Days - Make clickable and slightly more prominent */
.calendar-day.past {
  cursor: pointer;
  background: rgb(255 255 255 / 8%);
  opacity: 1;
}

.calendar-day.past:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgb(0 0 0 / 60%);
  background: rgb(255 255 255 / 12%);
}

/* Future Days - Make clearly disabled */
.calendar-day.future {
  background: rgb(255 255 255 / 2%);
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
}

/* Inactive Days (previous/next month) */
.calendar-day.calendar-day-inactive {
  opacity: 0.3;
  pointer-events: none;
  background: rgb(255 255 255 / 2%) !important;
  box-shadow: none;
}

/* Current Day Highlight */
.calendar-day.current-day {
  border: 2px solid var(--accent-color);
  background: rgb(187 134 252 / 10%);
}

/* Day Content Layout */
.day-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

/* Mood Emoji Positioning */
.calendar-day .mood-emoji {
  font-size: 1.2em;
  line-height: 1;
  margin-top: 2px;
}

/* Enhanced Hover States */
.calendar-day.past.has-mood:hover {
  background: rgb(255 255 255 / 15%);
}

/* Active State */
.calendar-day.active {
  background: rgb(187 134 252 / 15%);
  border-color: var(--accent-color);
  transform: translateY(-2px);
  z-index: 2;
}

/* Day Content */
.day-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-number {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color-dark);
}

/* Mood Selector Container - Position Fixed with Centered Animation */
.mood-selector-container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  background: rgb(0 0 0 / 0%);
}

.mood-selector-container.active {
  opacity: 1;
  visibility: visible;
}

/* Mood Selector Overlay - Initial Transform */
.mood-selector-overlay {
  position: relative;
  z-index: 1002;
  background: rgb(30 30 30 / 95%);
  border-radius: 16px;
  padding: 32px;
  max-width: 500px;
  width: 90%;
  color: #fff;
  box-shadow: 0 8px 24px rgb(0 0 0 / 60%);
  transform: scale(0.95) translateY(20px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.mood-selector-container.active .mood-selector-overlay {
  transform: scale(1) translateY(0);
  opacity: 1;
}

/* Selected Date */
.selected-date {
  font-size: 0.9rem;
  color: rgb(255 255 255 / 70%);
  text-align: center;
  margin-bottom: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

/* Mood Options Grid */
.mood-options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 16px;
  padding: 8px;
}

/* Mood Option */
.mood-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 12px;
  text-align: center;
  user-select: none;
}

/* Mood Emoji */
.mood-emoji {
  font-size: 32px;
  margin-bottom: 6px;
  transition: transform 0.2s ease;
}

/* Mood Label */
.mood-label {
  color: rgb(255 255 255 / 85%);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

/* Mood Option Hover Effects */
.mood-option:hover {
  transform: translateY(-3px);
  background: rgb(255 255 255 / 8%);
  border-color: rgb(255 255 255 / 20%);
}

.mood-option:hover .mood-emoji {
  transform: scale(1.1);
}

/* Selected Mood Option */
.mood-option.selected {
  background: rgb(187 134 252 / 20%);
  border: 1px solid rgb(187 134 252 / 40%);
  box-shadow: 0 0 12px rgb(187 134 252 / 30%);
}

/* Mobile Responsiveness */
@media (width <= 768px) {
  .mood-options-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  .mood-option {
    padding: 10px;
  }

  .mood-emoji {
    font-size: 28px;
  }

  .mood-label {
    font-size: 10px;
  }
}

/* Animations */

/* Update existing animations */
@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(20px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}


/* Dark Mode Toggle */
.calendar-footer {
  margin-top: var(--spacing-lg);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--surface-opacity-1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}



/* Mobile Responsiveness */
@media (width <= 768px) {
  :root {
    --calendar-cell-size: 45px;
    --spacing-md: 15px;
    --spacing-lg: 20px;
  }

  .calendar {
    padding: var(--spacing-md);
  }

}

  .calendar-day {
    padding: var(--spacing-xs);
  }

  .calendar-week-day div {
    font-size: 12px;
  }

  .day-number {
    font-size: 14px;
  }

  .mood-emoji {
    font-size: 20px;
  }

  .mood-label {
    font-size: 11px;
  }

  .month-picker {
    font-size: 20px;
  }

  #year {
    font-size: 16px;
  }


/* Container styles */
.mindfulness-features {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  max-width: 1200px;
  margin: 80px auto;
  padding: 0 60px;
  position: relative;
  perspective: 1000px; /* Add perspective for 3D effects */
}

/* Feature card styles */
.mindfulness-feature {
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 12px;
  padding: 40px;
  width: 300px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
  text-align: center;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity, box-shadow;
  transform-style: preserve-3d; /* Enable 3D transforms */
  backface-visibility: hidden; /* Prevent flickering */
}

/* Enhanced hover effects */
.mindfulness-feature:hover {
  transform: translateY(-12px) scale(1.02) rotateX(2deg);
  box-shadow: 
    0 20px 25px -5px rgb(0 0 0 / 40%),
    0 8px 10px -6px rgb(0 0 0 / 30%);
  background: rgb(255 255 255 / 8%);
}

/* Icon wrapper styles */
.feature-icon-wrapper {
  width: 64px;
  height: 64px;
  margin: 0 auto 32px;
  background: rgb(255 255 255 / 10%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(20px); /* Lift icon in 3D space */
  transition: transform 0.3s ease;
}

.mindfulness-feature:hover .feature-icon-wrapper {
  transform: translateZ(30px); /* Increase lift on hover */
}

/* Icon styles */
.feature-icon {
  display: block;
  margin: auto;
  transform: translateY(2px);
  transition: transform 0.3s ease;
}

/* Content styles */
.feature-content {
  transform: translateZ(10px); /* Slight lift for content */
}

.feature-content h3 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.feature-content p {
  color: #ccc;
  font-size: 1rem;
  line-height: 1.5;
}

/* Animation keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Animation classes */
.mindfulness-feature[data-anim] {
  animation: fadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.mindfulness-feature:nth-child(1) { animation-delay: 0.2s; }
.mindfulness-feature:nth-child(2) { animation-delay: 0.4s; }
.mindfulness-feature:nth-child(3) { animation-delay: 0.6s; }

/* Responsive styles */
@media (width <= 1024px) {
  .mindfulness-features {
    padding: 0 40px;
    gap: 30px;
  }
  
  .mindfulness-feature {
    width: calc(33.33% - 20px);
    padding: 30px;
  }
}

@media (width <= 768px) {
  .mindfulness-features {
    flex-direction: column;
    padding: 0 20px;
    gap: 30px;
    margin: 40px auto;
  }

  .mindfulness-feature {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;

    /* Maintain some hover effects on touch devices */
    transform: translateY(0) scale(1);
  }
  
  /* Adjust hover effects for touch devices */
  .mindfulness-feature:hover {
    transform: translateY(-8px) scale(1.01) rotateX(1deg);
  }
  
  .feature-icon-wrapper {
    margin-bottom: 24px;
  }
}

/* Handle devices that don't support hover */
@media (hover: none) {
  .mindfulness-feature:hover {
    transform: none;
    box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
  }
  
  .mindfulness-feature:active {
    transform: translateY(-4px);
    box-shadow: 0 12px 20px rgb(0 0 0 / 50%);
  }
}






/* Hover States */
@media (hover: hover) {
  .calendar-day.past:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-1);
  }

  .mood-option:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-1);
  }

  .year-change:not(:disabled):hover {
    transform: translateY(-1px);
  }
}

/* Loading States */
.calendar-day.loading {
  pointer-events: none;
  opacity: 0.5;
}

/* Active States */
.calendar-day.active {
  z-index: 2;
  position: relative;
}

/* Error States */
.calendar-day.error {
  border-color: var(--error-color);
}

