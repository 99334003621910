.export-button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.1s;
}

.export-button:hover {
  background-color: #000;
}

.export-button:active {
  transform: scale(0.98);
}

.export-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.export-button svg {
  margin-right: 8px;
}