:root {
    --primary: #6A00FF;
    --secondary: rgb(106 0 255 / 40%);
    --hover-overlay: rgb(106 0 255 / 15%);
    --sidebar-text-color: rgb(255 255 255 / 90%);
    --sidebar-transition: all 0.3s ease;
    --notification-badge: #FF1493;
    --glass-shine: linear-gradient(
        120deg,
        rgb(255 255 255 / 0%) 30%,
        rgb(255 255 255 / 3%) 50%,
        rgb(255 255 255 / 0%) 70%
    );
    --z-backdrop: 40;
    --z-sidebar: 50;
    --z-badge: 51;
    --z-notifications: 60;
    --z-header: 70;
}

.sidebar-backdrop {
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 70%);
    backdrop-filter: blur(4px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: var(--z-backdrop);
}




.sidebar-backdrop.open {
    opacity: 1;
    visibility: visible;
}

.sidebar-nav {
    position: fixed;
    top: 64px;
    left: 0;
    width: 280px;
    height: calc(100vh - 64px);
    background: linear-gradient(
      to bottom,
      rgb(15 15 15 / 95%),
      rgb(0 0 0 / 98%)
    );
    backdrop-filter: blur(20px);
    color: var(--sidebar-text-color);
    padding: 1.5rem 1rem;
    transform: translateX(-100%);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: var(--z-sidebar);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    border-right: 1px solid rgb(255 255 255 / 10%);
    box-shadow:
      inset -1px 0 0 0 rgb(255 255 255 / 5%),
      10px 0 30px -10px rgb(0 0 0 / 80%);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.sidebar-nav.open {
    transform: translateX(0);
}

.sidebar-link {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    min-height: 3.5rem;
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 0.75rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    gap: 1.25rem;
    background: linear-gradient(
      145deg,
      var(--hover-overlay),
      rgb(255 255 255 / 5%)
    );
    border: 1px solid rgb(255 255 255 / 10%);
    box-shadow:
      inset 0 1px 1px rgb(255 255 255 / 5%),
      0 2px 4px rgb(0 0 0 / 20%);
    position: relative;
    overflow: hidden;
    opacity: 0.8;
    font-size: 1rem;
    font-weight: 500;
    isolation: isolate; /* Create new stacking context */
        z-index: 3; /* Place content above the gradient */

}

.sidebar-link::before {
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: var(--glass-shine);
    transform: skewX(-15deg);
    transition: transform 0.7s ease;
    z-index: 0; /* Place gradient below content */
}

.sidebar-link:hover::before {
    transform: translateX(100%) skewX(-15deg);
}

/* Link States */
.sidebar-link:hover {
    opacity: 0.9;
    background: linear-gradient(
      145deg,
      var(--hover-overlay),
      var(--active-bg)
    );
    transform: translateY(-1px);
}

.sidebar-link.active {
    opacity: 1;
    background: linear-gradient(145deg, var(--primary), var(--active-bg));
    border: 1px solid var(--primary);
    box-shadow:
      inset 0 1px 1px rgb(255 255 255 / 10%),
      0 4px 8px var(--primary);
    text-shadow: 0 1px 3px rgb(0 0 0 / 50%);
    transform: translateY(0);
    color: rgb(255 255 255 / 100%); /* Just make text fully white */
}

/* Icon Wrapper */
.sidebar-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
}

.sidebar-link:hover .sidebar-icon-wrapper {
    color: var(--primary);
}

.sidebar-link.active .sidebar-icon-wrapper {
    color: var(--active-icon);
    filter: drop-shadow(0 0 6px rgb(0 0 0 / 20%));
}

/* Notification Badge */
.sidebar-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    background: var(--notification-badge);
    color: #FFF;
    font-size: 0.75rem;
    min-width: 18px;
    height: 18px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    box-shadow:
      0 0 0 2px rgb(0 0 0 / 80%),
      0 0 10px var(--notification-badge);
    border: 1px solid rgb(255 255 255 / 30%);
    animation: gentlePulse 2s infinite;
    z-index: var(--z-badge);
}

/* Animations */
@keyframes gentlePulse {
    0% { box-shadow: 0 0 0 2px rgb(0 0 0 / 80%), 0 0 10px var(--notification-badge); }
    50% { box-shadow: 0 0 0 2px rgb(0 0 0 / 80%), 0 0 15px var(--notification-badge); }
    100% { box-shadow: 0 0 0 2px rgb(0 0 0 / 80%), 0 0 10px var(--notification-badge); }
}

/* Mobile Optimizations */
@media (width <= 768px) {
    .sidebar-nav {
        width: 300px;
        padding: 1.25rem 1rem;
        gap: 1rem;
    }

    .sidebar-link {
        padding: 1.125rem 1.5rem;
        min-height: 4rem;
    }

    .sidebar-icon-wrapper {
        min-width: 28px;
        min-height: 28px;
    }

    .sidebar-backdrop {
        display: block;
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .sidebar-nav,
    .sidebar-link,
    .sidebar-backdrop {
        transition-duration: 0.1s;
    }
    
    .sidebar-badge {
        animation: none;
    }
}