:root {
  --bg-body-dark: #121212;
  --bg-secondary-dark: #252525;
  --text-color-dark: #FFF;
  --accent-color: #BB86FC;
  --accent-rgb: 187, 134, 252;
  --error-color: #ef4444;
}

/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background: #050508;
  color: var(--text-color-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

/* Main Layout */
.login-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #050508 0%,
    #080818 25%,
    #0a0a20 50%,
    #0c0c25 75%,
    #0e0e2a 100%
  );
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

/* Left Panel */
.left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.5rem;
  min-height: 200px;
}

.left-side .content {
  text-align: center;
  max-width: 400px;
  z-index: 1;
}

/* Logo */
.logo-container {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

/* Right Panel */
.right-side {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  min-height: calc(100vh - 200px);
  position: relative;
  isolation: isolate;
}

/* Login Card */
.login-card {
  width: 100%;
  max-width: 400px;
  padding: 1.5rem;
  background: rgba(26, 26, 46, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  isolation: isolate;
}

/* Typography */
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #fff;
}

p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Form Elements */
.form-group {
  margin-bottom: 1rem;
}

.form-input {
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--accent-color);
  background: rgba(255, 255, 255, 0.08);
}

/* Buttons */
.login-btn {
  width: 100%;
  height: 44px;
  background: linear-gradient(
    to right,
    #a855f7 0%,
    #8b5cf6 50%,
    #7c3aed 100%
  );
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.2s ease;
  position: relative;
  isolation: isolate;
}

.login-btn:hover:not(:disabled) {
  transform: translateY(-1px);
  background: linear-gradient(
    to right,
    #b366f8 0%,
    #9c6ef7 50%,
    #8b4fee 100%
  );
}

.login-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Divider */
.divider {
  margin: 1.5rem 0;
  text-align: center;
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.divider::before { left: 0; }
.divider::after { right: 0; }

/* Social Login */
.social-login {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  isolation: isolate;
}

.social-btn {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  font-size: 0.9375rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}

.social-btn::before {
  content: '';
  position: absolute;
  inset: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  opacity: 0;
}

.social-btn.google {
  background: #fff;
  color: #3c4043;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.social-btn.google::before {
  background: linear-gradient(
    180deg,
    rgba(66, 133, 244, 0.08) 0%,
    rgba(66, 133, 244, 0.02) 100%
  );
}

.social-btn.google:hover:not(:disabled) {
  background: #fff;
}

.social-btn.google:hover:not(:disabled)::before {
  opacity: 1;
}

.social-btn.apple {
  background: #000;
  color: #fff;
  border: 1px solid #333;
}

.social-btn.apple::before {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.social-btn.apple:hover:not(:disabled) {
  background: #000;
}

.social-btn.apple:hover:not(:disabled)::before {
  opacity: 1;
}

.social-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.social-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Error Message */
.error-message {
  color: var(--error-color);
  font-size: 0.875rem;
  padding: 0.75rem;
  background: rgba(239, 68, 68, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

/* Links */
.terms {
  text-align: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.terms-link {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 500;
}

.terms-link:hover {
  text-decoration: underline;
}

.back-link {
  color: var(--accent-color);
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

/* Loading State */
.loader {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Desktop Styles */
@media (min-width: 768px) {
  .login-page {
    flex-direction: row;
    height: 100vh;
  }

  .left-side {
    flex: 1;
    height: 100vh;
    min-height: unset;
    padding: 2.5rem;
  }

  .right-side {
    flex: 1;
    height: 100vh;
    min-height: unset;
    padding: 2.5rem;
    align-items: center;
  }

  .logo {
    width: 200px;
    height: 200px;
  }

  h2 {
    font-size: 2rem;
  }

  .login-card {
    padding: 2rem;
  }
}

/* Device Height Adaptations */
@media (max-height: 600px) and (max-width: 767px) {
  .login-page {
    height: auto;
  }

  .left-side {
    min-height: 150px;
  }

  .right-side {
    min-height: 450px;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .login-btn:hover,
  .social-btn:hover {
    transform: none;
  }
}