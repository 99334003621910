/* src/components/AudioPlayer/AudioPlayer.css */

/* Base container styles */
.audio-player-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  background-color: #0f1012;
  border-radius: 30px;
  width: 100%;
  max-width: 800px;
  min-width: 280px;
  box-sizing: border-box;
  box-shadow: 20px 20px 60px #0c0d0e, -20px -20px 60px #181b1e;
  overflow: hidden;
  gap: 1.5rem;
  margin: 0 auto;
}

/* Music Library Section Styles */
.music-library-section {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.select-wrapper {
  position: relative;
  width: 100%;
}

.music-library-select {
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background-color: #16181a;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: inset 5px 5px 10px #0c0d0e, 
              inset -5px -5px 10px #202326;
  padding-right: 40px;
}

.select-wrapper::after {
  content: '';
  position: absolute;
  right: 16px;
  top: 50%;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translateY(-75%) rotate(45deg);
  pointer-events: none;
  transition: transform 0.3s ease;
}

.music-library-select:hover:not(:disabled) {
  background-color: #1a1d20;
  border-color: rgba(255, 255, 255, 0.2);
}

.music-library-select:focus {
  outline: none;
  border-color: #7d12ff;
  box-shadow: 0 0 0 2px rgba(125, 18, 255, 0.2);
}

.music-library-select:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #141618;
}

.music-library-select option {
  background-color: #16181a;
  color: #fff;
  padding: 12px;
  font-size: 14px;
}

.music-library-select.session-created {
  border-color: #7d12ff;
  animation: glowPulse 2s infinite;
}

.music-library-select.music-loaded {
  border-color: #7d12ff;
  box-shadow: 0 0 15px rgba(125, 18, 255, 0.3);
  animation: none;
}

/* Waveform Container and Loading States */
.waveform-loading-container {
  width: 100%;
  height: 80px;
  position: relative;
  margin-bottom: 0.5rem;
  border-radius: 15px;
  overflow: hidden;
}

.waveform-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #141618;
  box-shadow: inset 5px 5px 10px #0c0d0e, 
              inset -5px -5px 10px #202326;
  transition: opacity 0.3s ease;
}

.waveform {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.waveform::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  opacity: 0.8;
  z-index: 2;
  pointer-events: none;
  animation: cursorBlink 1s infinite;
  transform: translateX(var(--progress-width, 0));
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 
              0 0 15px rgba(125, 18, 255, 0.4);
}

.waveform-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(15, 16, 18, 0.85);
  backdrop-filter: blur(4px);
  border-radius: 15px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.waveform-loading-container.loading .waveform-loading-overlay {
  opacity: 1;
  visibility: visible;
}

.waveform-loading-container.loading .waveform,
.waveform-loading-container.mixing .waveform {
  opacity: 0.5;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(125, 18, 255, 0.3);
  border-top-color: #7d12ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 12px;
}

.loading-text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.loading-progress {
  color: #7d12ff;
  font-size: 12px;
  margin-top: 4px;
}

/* Progress Bar for Mixing */
.mixing-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(125, 18, 255, 0.2);
  z-index: 11;
}

.mixing-progress-bar {
  height: 100%;
  background: #7d12ff;
  transition: width 0.3s ease;
  box-shadow: 0 0 10px rgba(125, 18, 255, 0.5);
}

/* Custom Cursor */
.custom-cursor {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 123, 0, 0.2),
    #ececeb,
    rgba(255, 123, 0, 0.2)
  );
  z-index: 2;
  pointer-events: none;
  transition: left 0.1s ease;
  box-shadow: 0 0 10px #fff, 0 0 20px #cbcac8;
  transform: translateX(-50%);
}

/* Scrubber Styles */
.scrubber-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem;
  background-color: #16181a;
  border-radius: 15px;
  box-shadow: inset 5px 5px 10px #0c0d0e,
              inset -5px -5px 10px #202326;
  gap: 0.75rem;
}

.current-time,
.total-duration {
  color: #a0a0a0;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  background-color: #1a1d20;
  border-radius: 8px;
  box-shadow: 2px 2px 4px #0c0d0e,
              -2px -2px 4px #242729;
  min-width: 48px;
  text-align: center;
}

.scrubber-container {
  position: relative;
  flex-grow: 1;
  height: 10px;
  background-color: #1e2023;
  border-radius: 10px;
  box-shadow: inset 3px 3px 6px #0c0d0e,
              inset -3px -3px 6px #202326;
  cursor: pointer;
  margin: 0 0.5rem;
}

.scrubber-handle {
  position: absolute;
  top: -6px;
  width: 20px;
  height: 20px;
  background-color: #797a7f;
  border-radius: 50%;
  box-shadow: 4px 4px 8px #0c0d0e,
              -4px -4px 8px #181b1e;
  transition: left 0.1s ease;
  transform: translateX(-50%);
}

/* Control Buttons */
.wavesurfer-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
}

.control-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  background-color: #16181a;
  box-shadow: 8px 8px 16px #0c0d0e,
              -8px -8px 16px #202326;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn svg {
  width: 24px;
  height: 24px;
  fill: #a0a0a0;
  transition: fill 0.3s ease;
}

.control-btn:hover:not(:disabled) {
  box-shadow: 10px 10px 20px #0c0d0e,
              -10px -10px 20px #202326;
}

.control-btn:active:not(:disabled) {
  box-shadow: inset 8px 8px 16px #0c0d0e,
              inset -8px -8px 16px #202326;
}

.control-btn.playing {
  border-color: #7d12ff;
}

.control-btn.playing svg {
  fill: #7d12ff;
}

.control-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Music Library Dropdown */
.music-library-section {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

/* ... (additional styles as needed) */

/* Keyframes */
@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes glowPulse {
  0%, 100% { box-shadow: 0 0 5px rgba(125, 18, 255, 0.3); }
  50% { box-shadow: 0 0 15px rgba(125, 18, 255, 0.5); }
}

@keyframes cursorBlink {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.4; }
}

/* Responsive Styles */
@media (min-width: 1100px) {
  .audio-player-container {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .audio-player-container {
    padding: 1rem;
    margin: 0.75rem 0;
    border-radius: 20px;
    gap: 1rem;
  }

  .music-library-select {
    height: 40px;
    font-size: 13px;
  }

  .control-btn {
    width: 50px;
    height: 50px;
  }

  .control-btn svg {
    width: 20px;
    height: 20px;
  }

  .current-time,
  .total-duration {
    font-size: 0.75rem;
    padding: 0.35rem 0.5rem;
    min-width: 42px;
  }

  .scrubber-handle {
    width: 16px;
    height: 16px;
    top: -4px;
  }

  .loading-spinner {
    width: 32px;
    height: 32px;
    border-width: 2px;
  }
  
  .loading-text {
    font-size: 13px;
  }
  
  .loading-progress {
    font-size: 11px;
  }

  .filters-apply-button {
    padding: 10px 20px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .audio-player-container {
    padding: 0.5rem;
    border-radius: 15px;
    gap: 0.5rem;
  }

  .music-library-select {
    height: 32px;
    font-size: 11px;
    padding: 0 10px;
  }

  .waveform-loading-container {
    height: 50px;
  }

  .wavesurfer-controls {
    gap: 0.5rem;
  }

  .control-btn {
    width: 40px;
    height: 40px;
  }

  .control-btn svg {
    width: 16px;
    height: 16px;
  }

  .scrubber-container {
    height: 8px;
  }

  .scrubber-handle {
    width: 14px;
    height: 14px;
    top: -4px;
  }

  .loading-spinner {
    width: 24px;
    height: 24px;
    border-width: 2px;
  }
  
  .loading-text {
    font-size: 11px;
  }

  .loading-progress {
    font-size: 10px;
  }

  .filters-apply-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .current-time,
  .total-duration {
    font-size: 0.7rem;
    padding: 0.25rem 0.4rem;
    min-width: 38px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    transition: none !important;
    animation: none !important;
  }
}

@media (prefers-contrast: high) {
  .filter-slider,
  .slider {
    border: 1px solid #fff;
  }

  .neomorphic-checkbox,
  .filter-wrapper {
    border: 1px solid #fff;
  }
}

@media (prefers-color-scheme: dark) {
  .filter-select,
  .slider-value {
    background-color: #1a1a1a;
  }

  .filter-wrapper,
  .filter-controls {
    background-color: #0d0d0d;
  }
}
