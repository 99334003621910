/* Base preloader styles */
.preloader {
  text-align: center;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out,
              transform 0.3s ease-in-out,
              margin 0.3s ease-in-out;
}

.preloader.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.preloader.fade-exit {
  opacity: 0;
  transform: translateY(-10px);
}

.preloader-img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out,
              transform 0.3s ease-in-out,
              max-height 0.3s ease-in-out,
              margin 0.3s ease-in-out;
}

/* Action-specific styles with transitions */
.preloader-img.creating-session {
  margin-bottom: 2rem;
  max-height: 250px;
  transition: max-height 0.3s ease-in-out,
              margin-bottom 0.3s ease-in-out;
}

.preloader-img.generating-script {
  margin: 20px 0;
  transition: margin 0.3s ease-in-out;
}

.preloader-img.transcribing {
  max-width: 100%;
  height: auto;
  transition: max-width 0.3s ease-in-out;
}

.preloader-img.mixing-audio {
  max-height: 80px;
  transition: max-height 0.3s ease-in-out;
}

/* Player wrapper with transitions */
.player-wrapper {
  width: 100%;
  min-height: 200px;
  margin: 20px 0;
  border-radius: 12px;
  overflow: hidden;
  transition: min-height 0.3s ease-in-out;
}

/* Audio player container */
.audio-player {
  width: 100%;
  background: #0f1012;
  border-radius: 12px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 70%),
              -5px -5px 15px rgb(255 255 255 / 10%);
  transition: background 0.3s ease-in-out,
              box-shadow 0.3s ease-in-out;
}

/* Mixing audio state with transitions */
.player-preloader-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 12px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out,
              transform 0.3s ease-in-out,
              min-height 0.3s ease-in-out;
}

.player-preloader-container .preloader {
  padding: 0;
  margin: 0;
}

/* Message styles with transitions */
.preloader .message {
  margin-top: 15px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #e0e0e0;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out,
              transform 0.3s ease-in-out,
              margin-top 0.3s ease-in-out,
              font-size 0.3s ease-in-out;
}

/* State classes for transitions */
.preloader.hiding {
  opacity: 0;
  transform: translateY(-10px);
}

.preloader.showing {
  opacity: 1;
  transform: translateY(0);
}

/* Mobile optimizations with transitions */
@media (width <= 768px) {
  .player-wrapper,
  .player-preloader-container {
    min-height: 180px;
  }

  .preloader-img.mixing-audio {
    max-height: 70px;
  }
}

@media (width <= 480px) {
  .player-wrapper,
  .player-preloader-container {
    min-height: 160px;
  }

  .preloader-img.mixing-audio {
    max-height: 60px;
  }

  .preloader .message {
    font-size: 12px;
  }
}

/* iPhone SE and smaller devices */
@media (width <= 375px) {
  .player-wrapper,
  .player-preloader-container {
    min-height: 140px;
  }

  .preloader-img.mixing-audio {
    max-height: 50px;
  }
}

/* Animation keyframes for loading indicators */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.preloader-img.generating-script {
  animation: pulse 2s infinite;
}