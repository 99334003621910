/* Push to Speak Button Styles */
.PushButton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  position: relative;
  cursor: pointer;
}

/* Tooltip Container */
.PushButton-tooltip {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(145deg, var(--dark-bg), var(--darker-bg));
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-primary);
  width: max-content;
  max-width: 250px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow:
    5px 5px 10px var(--shadow-dark),
    -5px -5px 10px var(--shadow-light),
    inset 1px 1px 2px rgb(255 255 255 / 5%);
  z-index: 1000;
  pointer-events: none;
  text-align: center;
}

/* Tooltip Arrow */
.PushButton-tooltip::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0;
  border-style: solid;
  border-color: var(--dark-bg) transparent transparent transparent;
}

/* Show Tooltip on Hover (Desktop) */
@media (hover: hover) {
  .PushButton-wrapper:hover .PushButton-tooltip {
    opacity: 1;
    visibility: visible;
    top: -80px;
  }
}

/* Show Tooltip for Touch Devices */
@media (hover: none) {
  .PushButton-wrapper:active .PushButton-tooltip,
  .PushButton-wrapper.touched .PushButton-tooltip {
    opacity: 1;
    visibility: visible;
    top: -80px;
  }
}

/* PushButton Base Styles */
.PushButton {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(145deg, var(--dark-bg), var(--darker-bg));
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    10px 10px 20px var(--shadow-dark),
    -10px -10px 20px var(--shadow-light);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

/* Dynamic Glow Underneath the Button */
.PushButton::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--primary) 20%, transparent 80%);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Glow on Hover */
.PushButton:hover::before {
  opacity: 0.3;
  transform: translate(-50%, -50%) scale(1.1);
}

/* PushButton Icon */
.PushButton-icon {
  width: 40px;
  height: 40px;
  fill: var(--text-primary);
  transition: transform 0.2s ease-in-out, fill 0.3s ease;
}

/* Active State Icon */
.PushButton.active .PushButton-icon {
  fill: var(--primary);
}

/* PushButton Active State */
.PushButton.active {
  background: linear-gradient(145deg, var(--primary-light), var(--primary-dark));
  color: var(--text-primary);
  box-shadow:
    inset 8px 8px 16px var(--shadow-dark),
    inset -8px -8px 16px var(--shadow-light);
}

/* Hover and Focus Effects */
.PushButton:hover:not(.disabled),
.PushButton:focus:not(.disabled) {
  box-shadow: 0 0 15px var(--primary);
  transform: scale(1.05);
}

/* Active Click Effect */
.PushButton:active:not(.disabled) {
  box-shadow:
    inset 8px 8px 16px var(--shadow-dark),
    inset -8px -8px 16px var(--shadow-light);
  transform: scale(0.95);
}

/* Disabled State */
.PushButton:disabled {
  cursor: not-allowed;
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  box-shadow:
    inset 5px 5px 10px var(--shadow-dark),
    inset -5px -5px 10px var(--shadow-light);
}

/* PushButton Label */
.PushButton-label {
  color: var(--text-primary);
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

/* Tooltip Animation */
@keyframes tooltipFade {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Mobile Optimizations */
@media (width <= 768px) {
  .PushButton {
    width: 65px;
    height: 65px;
  }

  .PushButton-icon {
    width: 30px;
    height: 30px;
  }

  .PushButton-label {
    font-size: 12px;
  }

  .PushButton-tooltip {
    font-size: 12px;
    padding: 10px 16px;
    max-width: 200px;
  }
}

/* Very Small Screens */
@media (width <= 320px) {
  .PushButton {
    width: 55px;
    height: 55px;
  }

  .PushButton-icon {
    width: 25px;
    height: 25px;
  }

  .PushButton-label {
    font-size: 10px;
  }

  .PushButton-tooltip {
    font-size: 10px;
    padding: 8px 12px;
    max-width: 180px;
  }
}
