/* Voice Selector Styles */
.voice-selector-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: center;
}

.voice-selector {
  padding: 5px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #0f1012;
  color: #e0e0e0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 3rem; /* Add more space below the voice selector */
  transition: box-shadow 0.3s;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 70%), -5px -5px 15px rgb(255 255 255 / 10%);
}

.voice-selector-container select {
  box-sizing: border-box;
  background-color: #16181a;
  color: #e0e0e0;
  border: none;
  padding: 0.75rem;
  border-radius: 15px;
  width: 100%;
  max-width: 946px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%), inset -5px -5px 10px rgb(255 255 255 / 10%);
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23e0e0e0" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.voice-selector-container select:focus {
  outline: none;
  background-color: #1a1d20;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%), inset -5px -5px 10px rgb(255 255 255 / 10%),
              0 0 15px rgb(146 68 255 / 30%);
}

/* Hover effect */
.voice-selector-container select:hover {
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%), inset -5px -5px 10px rgb(255 255 255 / 10%),
              0 0 10px rgb(146 68 255 / 20%);
}

/* Mobile Optimization for Tablets */
@media (width <= 768px) {
  .voice-selector, .voice-selector-container select {
    font-size: 14px;
  }

  .voice-selector-container select {
    padding: 0.6rem;
  }
}

/* Mobile Optimization for Small Phones */
@media (width <= 480px) {
  .voice-selector, .voice-selector-container select {
    font-size: 12px;
  }

  .voice-selector-container select {
    padding: 0.5rem;
  }

  .voice-selector-container {
    margin-bottom: 1.5rem;
  }
}