/* Volume Controls Container and Wrapper */
.volume-controls-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  position: relative;
  display: block; /* Ensure it's displayed */
}

.volume-controls {
  width: 100%;
  max-width: 800px;
  min-width: 280px;
  background-color: #000;
  border-radius: 30px;
  padding: 25px;
  color: #e0e0e0;
  font-family: Arial, sans-serif;
  position: relative;
  margin: 0 auto;
  box-shadow: 
      20px 20px 60px #000000,
      -20px -20px 60px #000000;
  display: block; /* Ensure it's displayed */
}

/* Volume Section Styles */
.volume-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  margin: 0 0 30px 0;
  box-sizing: border-box;
}

.volume-sliders {
  display: flex;
  flex-direction: column;
  gap: 15px;
}



/* Slider Container and Components */
.slider-container {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.5),
    inset -5px -5px 10px rgba(255, 255, 255, 0.05);
  margin-bottom: 15px;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
}

.slider-value {
  min-width: 60px;
  text-align: right;
  font-size: 14px;
  color: #a020f0;
  font-weight: 500;
}

/* Neomorphic Slider Styles */
.neomorphic-slider {
  appearance: none;
  width: 100%;
  height: 6px;
  background: #444;
  border-radius: 50px;
  outline: none;
  transition: background 0.3s ease;
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.6),
    inset -5px -5px 10px rgba(255, 255, 255, 0.1),
    0 2px 4px rgba(0, 0, 0, 0.2);
}

.neomorphic-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle, #a020f0, #8010c0);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.8);
  cursor: pointer;
  box-shadow: 
    0 0 10px rgba(160, 32, 240, 0.5),
    0 2px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.neomorphic-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  box-shadow: 
    0 0 20px rgba(160, 32, 240, 0.7),
    0 4px 8px rgba(0, 0, 0, 0.5);
}

.neomorphic-slider::-webkit-slider-thumb:active {
  transform: scale(0.95);
  box-shadow: 
    0 0 10px rgba(160, 32, 240, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.5);
}

/* Control Labels */
.control-label {
  font-size: 16px;
  margin-bottom: 10px;
  color: #e0e0e0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  font-weight: 500;
  display: block;
}

/* Filters Section */
.filters-section {
  margin-top: 30px;
  padding: 0 20px;
}

.filters-section h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Filters Grid */
.filters-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

/* Filter Wrapper */
.filter-wrapper {
  background-color: #0f1012;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    10px 10px 30px #0c0d0e,
    -10px -10px 30px #181b1e;
}

.filter-wrapper.expanded {
  box-shadow: 
    15px 15px 40px #0a0b0c,
    -15px -15px 40px #1a1d20;
}

/* Filter Header */
.filter-header {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #111;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: background-color 0.3s ease;
}

.filter-header:hover {
  background-color: #181818;
}

/* Filter Toggle & Controls */
.filter-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 3;
  pointer-events: auto;
}

.filter-toggle span {
  color: #e0e0e0;
  font-size: 15px;
  font-weight: 500;
}

/* Neomorphic Checkbox */
.neomorphic-checkbox {
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: #222;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.6),
    inset -5px -5px 10px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3),
    -5px -5px 15px rgba(255, 255, 255, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.neomorphic-checkbox:checked {
  background: #a020f0;
  box-shadow: 
    inset 2px 2px 5px rgba(0, 0, 0, 0.9),
    inset -2px -2px 5px rgba(160, 32, 240, 0.25),
    5px 5px 15px rgba(0, 0, 0, 0.3),
    -5px -5px 15px rgba(255, 255, 255, 0.1);
}

.neomorphic-checkbox::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.neomorphic-checkbox:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

/* Filter Controls Content */
.filter-controls {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, 
              opacity 0.3s ease-in-out,
              visibility 0s linear 0.3s;
}

.filter-controls.enabled {
  max-height: 1500px;
  opacity: 1;
  visibility: visible;
  padding: 20px;
  overflow: auto;
  transition: max-height 0.3s ease-in-out,
              opacity 0.3s ease-in-out,
              visibility 0s linear 0s;
}

.filter-controls.disabled {
  max-height: 1000px;
  opacity: 0.6;
  visibility: visible;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
}

.filter-controls-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Neomorphic Select */
.neomorphic-select {
  width: 100%;
  padding: 10px;
  background-color: #222;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 
    inset 2px 2px 6px rgba(0, 0, 0, 0.8),
    inset -2px -2px 6px rgba(255, 255, 255, 0.05),
    2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.neomorphic-select:focus {
  outline: none;
  box-shadow: 
    inset 3px 3px 8px rgba(0, 0, 0, 0.9),
    inset -3px -3px 8px rgba(255, 255, 255, 0.07),
    0 0 10px rgba(160, 32, 240, 0.3);
}

/* Apply Changes Button */
.filters-apply-button-container {
  position: sticky;
  bottom: 20px;
  z-index: 10;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.filters-apply-button {
  width: 100%;
  background: linear-gradient(145deg, #a020f0, #8010c0);
  padding: 12px 30px;
  border: none;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    5px 5px 10px rgba(0, 0, 0, 0.3),
    -5px -5px 10px rgba(255, 255, 255, 0.1);
}

.filters-apply-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 
    8px 8px 15px rgba(0, 0, 0, 0.3),
    -8px -8px 15px rgba(255, 255, 255, 0.1);
}

.filters-apply-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.3),
    inset -5px -5px 10px rgba(255, 255, 255, 0.1);
}

.filters-apply-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: linear-gradient(145deg, #666, #444);
}



.solo-mode-indicator {
  background-color: rgba(160, 32, 240, 0.1);
  border: 1px solid #a020f0;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 20px;
}

.solo-mode-text {
  color: #a020f0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}

.solo-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.solo-toggle-label {
  color: #e0e0e0;
  font-size: 14px;
  font-weight: 500;
}

/* Tooltip Container */
.tooltip-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip {
  font-size: 14px;
  color: #a020f0;
  cursor: help;
}

/* Responsive Design */
@media (max-width: 768px) {
  .volume-controls {
    padding: 20px;
    border-radius: 20px;
  }

  .filters-grid {
    grid-template-columns: 1fr;
  }

  .slider-container {
    padding: 10px 15px;
  }

  .filters-apply-button-container {
    max-width: 90%;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
  }
}

/* Focus States */
.filter-header:focus-visible,
.neomorphic-checkbox:focus-visible,
.neomorphic-slider:focus-visible,
.neomorphic-select:focus-visible,
.filters-apply-button:focus-visible {
  outline: 2px solid #a020f0;
  outline-offset: 2px;
}