.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 64px;
    background-color: #0A0A0A;
    background-image: linear-gradient(
      to right,
      var(--nav-background-color),
      #0A0A0A
    );
    color: var(--text-primary);
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    z-index: 1000;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--hover-overlay);
  }
  
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  

/* Common button styles for all circular buttons */
.theme-trigger,
.notifications-trigger,
.logout-button,
.hamburger-button {
  position: relative;
  width: 40px;  /* Increased from 32px */
  height: 40px; /* Increased from 32px */
  background: var(--hover-overlay);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--primary);
}
  
.theme-trigger[data-active="true"],
.notifications-trigger[data-has-notifications="true"] {
  background: var(--hover-overlay);
  box-shadow: 0 0 12px var(--hover-overlay);
  transform: scale(1.1);
}
  

  .theme-trigger:hover,
.notifications-trigger:hover,
.logout-button:hover,
.hamburger-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 12px var(--hover-overlay);
}

/* Notification Badge Positioning */
.notifications-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
/* Notification Badge Styling */
.notifications-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    background-color: var(--notification-badge); /* Always hot magenta */
    color: white;
    border-radius: 50%;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    pointer-events: none;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--bg-primary);
}

  

  @keyframes orb-glow {
    0% {
      box-shadow: 0 0 8px var(--hover-overlay);
    }

    100% {
      box-shadow: 0 0 12px var(--hover-overlay);
    }
  }
  
/* Ensure the badge stays visible on hover */
.notifications-trigger:hover .notifications-badge {
    transform: scale(1.1);
    box-shadow: 0 0 8px var(--notification-badge);
}

/* Ensure the badge always maintains its color */
.notifications-badge {
    background-color: var(--notification-badge) !important;
}

  /* Ensure the badge is always visible */
  .notifications-badge {
    transform-origin: center;
    transition: transform 0.2s ease;
  }