.header-container {
  position: relative; /* Essential for positioning the preloader absolutely within */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
  width: 100%; /* Full width to accommodate background or full header design */
  max-width: 946px; /* Maximum width of the header */
  margin: 0 auto; /* Center the header within its parent */
  padding: 20px; /* Padding around the header for spacing */

  /* Additional styles for shadow, border, etc., can go here */
}



/* You might want to include media queries for responsiveness */
@media (width <= 768px) {
  .header-container {
    padding: 15px; /* Adjust padding for smaller screens */
  }
}
