/* Base Layout */
.signup-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background: linear-gradient(
    180deg,
    #050508 0%,
    #080818 25%,
    #0a0a20 50%,
    #0c0c25 75%,
    #0e0e2a 100%
  );
  overflow: hidden;
}

.background-layer {
  position: fixed;
  inset: 0;
  background: radial-gradient(circle at center, rgb(187 134 252 / 3%) 0%, transparent 70%);
  z-index: 0;
}

/* Logo Section */
.logo-section {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
}

.auth-logo {
  width: 120px;
  height: auto;
  filter: drop-shadow(0 4px 12px rgb(0 0 0 / 20%));
  transition: transform 0.3s ease;
}

.auth-logo:hover {
  transform: scale(1.05);
}

/* Auth Container */
.auth-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.auth-content {
  width: 100%;
  max-width: 480px;
  padding: 48px;
  background: rgb(26 26 46 / 50%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgb(0 0 0 / 40%);
  backdrop-filter: blur(12px);
  animation: fade-in-up 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}



/* Step Indicator */
.step-indicator {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  color: rgb(255 255 255 / 60%);
  font-size: 0.9rem;
  position: relative;
  transition: all 0.3s ease;
}

.step::after {
  content: '';
  position: absolute;
  right: -2rem;
  top: 50%;
  width: 2rem;
  height: 1px;
  background: rgb(255 255 255 / 10%);
}

.step:last-child::after {
  display: none;
}

.step.active {
  background: var(--accent-gradient);
  border-color: var(--accent-color);
  color: #fff;
  transform: scale(1.1);
}

.step.completed {
  background: var(--accent-color);
  border-color: var(--accent-color);
  color: #fff;
}

/* Form Styles */
.auth-title {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;
  letter-spacing: -0.02em;
}

.auth-subtitle {
  font-size: 1rem;
  color: rgb(255 255 255 / 60%);
  text-align: center;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: rgb(255 255 255 / 80%);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.auth-input {
  width: 100%;
  padding: 16px 20px;
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 12px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.auth-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgb(187 134 252 / 20%);
  background: rgb(255 255 255 / 8%);
}

/* Password Requirements */
.password-requirements {
  margin-top: 1rem;
  padding: 1rem;
  background: rgb(255 255 255 / 3%);
  border-radius: 12px;
}

.password-requirements p {
  color: rgb(255 255 255 / 80%);
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}

.password-requirements ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.password-requirements li {
  color: rgb(255 255 255 / 60%);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.password-requirements li::before {
  content: '○';
  color: rgb(255 255 255 / 40%);
}

.password-requirements li.met {
  color: var(--accent-color);
}

.password-requirements li.met::before {
  content: '●';
  color: var(--accent-color);
}

/* Experience Buttons */
.experience-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.experience-button {
  flex: 1;
  padding: 12px;
  background: rgb(255 255 255 / 5%);
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 12px;
  color: rgb(255 255 255 / 80%);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.experience-button:hover {
  background: rgb(255 255 255 / 8%);
  transform: translateY(-1px);
}

.experience-button.active {
  background: var(--accent-gradient);
  border-color: var(--accent-color);
  color: #fff;
}

/* Form Buttons */
.form-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.auth-button {
  flex: 1;
  padding: 16px 24px;
  background: var(--accent-gradient);
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgb(187 134 252 / 30%);
}

.auth-button.secondary {
  background: transparent;
  border: 1px solid rgb(255 255 255 / 20%);
  color: rgb(255 255 255 / 80%);
}

.auth-button.secondary:hover {
  background: rgb(255 255 255 / 5%);
  border-color: rgb(255 255 255 / 30%);
}

/* Social Auth */
.auth-divider {
  margin: 2rem 0;
  position: relative;
  text-align: center;
}

.auth-divider span {
  padding: 0 1rem;
  background: rgb(26 26 46 / 50%);
  color: rgb(255 255 255 / 60%);
  font-size: 0.9rem;
  position: relative;
  z-index: 1;
}

.auth-divider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: rgb(255 255 255 / 10%);
}

.social-auth {
  display: flex;
  gap: 1rem;
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px
}

.social-btn.apple {
  background: linear-gradient(180deg, #1a1a1a 0%, #000 100%);
  color: #fff;
  border: 1px solid #333;
  box-shadow: 
    0 1px 3px rgb(0 0 0 / 20%),
    inset 0 1px 0 rgb(255 255 255 / 10%);
}

.social-btn.apple:hover {
  background: linear-gradient(180deg, #2a2a2a 0%, #1a1a1a 100%);
  box-shadow: 
    0 2px 4px rgb(0 0 0 / 30%),
    inset 0 1px 0 rgb(255 255 255 / 15%);
  transform: translateY(-1px);
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-icon {
  width: 18px;
  height: 18px;
}

.apple-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 0; /* Adjusted alignment for centering */
  color: #fff;
}

.social-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgb(255 255 255 / 10%) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  border-radius: 12px 12px 0 0;
}

/* Footer */
.auth-footer {
  margin-top: 2rem;
  text-align: center;
  color: rgb(255 255 255 / 60%);
  font-size: 0.9rem;
}

.auth-link {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.auth-link:hover {
  color: #fff;
}

/* Loading State */
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(255 255 255 / 30%);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error Message */
.error-message {
  padding: 12px;
  margin-bottom: 1.5rem;
  background: rgb(255 87 87 / 10%);
  border: 1px solid rgb(255 87 87 / 30%);
  border-radius: 12px;
  color: #ff5757;
  font-size: 0.9rem;
  text-align: center;
}

/* Responsive Design */
@media (width <= 768px) {
  .auth-container {
    padding: 20px;
  }

  .auth-content {
    padding: 32px;
  }

  .logo-section {
    top: 20px;
    left: 20px;
  }

  .auth-logo {
    width: 100px;
  }

  .experience-buttons {
    flex-direction: column;
  }

  .social-auth {
    flex-direction: column;
  }
}
