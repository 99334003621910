/* LoadingIndicator.css */
.loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #16181a;
    border-radius: 15px;
    box-shadow: 8px 8px 16px #0c0d0e,
                -8px -8px 16px #202326;
  }
  
  .spinner-container {
    position: relative;
    width: 40px;
    height: 40px;
    background: #16181a;
    border-radius: 50%;
    box-shadow: inset 4px 4px 8px #0c0d0e,
                inset -4px -4px 8px #202326;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    width: 28px;
    height: 28px;
    border: 3px solid rgba(125, 18, 255, 0.1);
    border-top-color: #7d12ff;
    border-radius: 50%;
    animation: spin 1s cubic-bezier(0.76, 0, 0.24, 1) infinite;
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
  
  .loading-text {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    text-shadow: 1px 1px 2px #0c0d0e;
  }
  
  .loading-progress {
    color: #7d12ff;
    font-size: 0.75rem;
    font-weight: 600;
  }
  
  @keyframes spin {
    to { 
      transform: rotate(360deg);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .loading-indicator {
      padding: 1.25rem;
      gap: 0.875rem;
    }
  
    .spinner-container {
      width: 36px;
      height: 36px;
    }
  
    .spinner {
      width: 24px;
      height: 24px;
    }
  
    .loading-text {
      font-size: 0.8125rem;
    }
  
    .loading-progress {
      font-size: 0.6875rem;
    }
  }
  
  @media (max-width: 480px) {
    .loading-indicator {
      padding: 1rem;
      gap: 0.75rem;
    }
  
    .spinner-container {
      width: 32px;
      height: 32px;
    }
  
    .spinner {
      width: 22px;
      height: 22px;
      border-width: 2.5px;
    }
  
    .loading-text {
      font-size: 0.75rem;
    }
  
    .loading-progress {
      font-size: 0.625rem;
    }
  }