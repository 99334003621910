/* Settings Page Layout */
.settings-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    background: var(--background-color);
    color: var(--text-secondary);
}

.settings-container {
    width: 100%;
    max-width: 600px;
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 50%);
    border: 1px solid var(--hover-overlay);
}

/* Headers */
.settings-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 24px;
    color: var(--text-primary);
    font-weight: 500;
}

.settings-group {
    margin-bottom: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--hover-overlay);
}

.settings-group:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.settings-subtitle {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: var(--text-primary);
    font-weight: 500;
}

.settings-subtitle svg {
    color: var(--primary);
}

/* Form Elements */
.settings-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.settings-item:last-child {
    margin-bottom: 0;
}

.settings-item label {
    color: var(--text-primary);
    font-size: 0.9rem;
    font-weight: 500;
}

.settings-input {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--hover-overlay);
    background: var(--bg-primary);
    color: var(--text-primary);
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.settings-input:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 0 2px var(--hover-overlay);
}

.settings-input::placeholder {
    color: var(--text-secondary);
}

/* Custom Checkboxes */
.settings-item.checkbox {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}

.settings-checkbox {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid var(--primary);
    border-radius: 6px;
    background: transparent;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.settings-checkbox:checked {
    background: var(--primary);
}

.settings-checkbox::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0.8);
    transition: all 0.2s ease;
}

.settings-checkbox:checked::after {
    opacity: 1;
    transform: scale(1);
}

.settings-checkbox:hover {
    border-color: var(--primary);
    box-shadow: 0 0 0 4px var(--hover-overlay);
}

/* Buttons */
.settings-btn {
    background: var(--primary);
    color: var(--text-primary);
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.settings-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px var(--hover-overlay);
}

.settings-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
}

.danger-btn {
    background: #dc3545;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.danger-btn:hover {
    background: #c82333;
    transform: translateY(-1px);
}

.secondary-btn {
    background: var(--hover-overlay);
    color: var(--text-primary);
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.secondary-btn:hover {
    background: var(--active-bg);
    transform: translateY(-1px);
}

/* Responsive Design */
@media (width >= 768px) {
    .settings-page {
        padding: 32px;
    }

    .settings-container {
        max-width: 800px;
    }

    .settings-actions {
        flex-direction: row;
        justify-content: flex-start;
    }

    .settings-btn {
        align-self: flex-start;
    }
}

/* Input Focus Styles */
.settings-input:focus,
.settings-checkbox:focus,
.settings-btn:focus,
.danger-btn:focus,
.secondary-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--hover-overlay);
}

/* Animation for Checkbox */
@keyframes checkmark {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.settings-checkbox:checked::after {
    animation: checkmark 0.2s ease forwards;
}