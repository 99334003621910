

body, html {
  font-family: Inter, sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-primary);
  overflow-x: hidden;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: inherit;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main-layout {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.main-content {
  flex: 1;
  padding: 16px;
  background-color: var(--dark-bg);
  transition: background-color var(--transition-speed);
}

/* Set minimum widths and better scaling */
.meditation-content {
  width: 100%;
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.audio-controls-container {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border-radius: 20px;
  margin-top: 30px;
  padding: 20px;
  box-sizing: border-box;
}


.tree-control-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.decision-tree-wrapper {
  width: 100%;
  margin-top: 20px;
}

.wave-btn-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}

.wave-btn {
  background: var(--button-gradient);
  border: none;
  color: var(--text-color);
  padding: 12px 24px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 12px var(--hover-overlay);
}

.wave-btn:hover {
  transform: translateY(-2px);
  animation: glowing 1.5s infinite;
}

.text-container {
  position: relative;
  width: 80%;
  max-width: 946px;
  margin: 30px auto;
  background-color: #0f1012;
  border-radius: 20px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 70%), 
              -5px -5px 15px rgb(255 255 255 / 10%);
  transition: all 0.3s ease-in-out;
}

.text-container.prompt {
  height: 200px;
}

.text-container.script {
  height: 300px;
}

.text-area {
  width: 100%;
  height: 100%;
  padding: 15px;
  border: none;
  border-radius: 15px;
  resize: none;
  outline: none;
  font-size: 16px;
  background-color: #16181a;
  color: var(--text-color);
  transition: background-color 0.3s ease;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
              inset -5px -5px 10px rgb(255 255 255 / 10%);
}

.main-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.voice-selector-container {
  width: 100%;
  margin: 10px 0;
}

/* State-Specific Styles */
.meditation-content:not(.decision-tree-open) {
  justify-content: flex-start;
  padding-top: 40px;
}

.meditation-content:not(.decision-tree-open) .main-controls {
  margin-bottom: 20px;
}

.meditation-content:not(.decision-tree-open) .text-container {
  margin-top: 20px;
}

.meditation-content.decision-tree-open .text-container {
  margin-top: 10px;
}

@keyframes glowing {
  0%, 100% {
    box-shadow: 0 0 8px var(--primary),
                0 0 16px var(--primary);
  }

  50% {
    box-shadow: 0 0 16px var(--primary),
                0 0 32px var(--primary);
  }
}

@media (width <= 1200px) {
  .text-container {
    width: 90%;
  }
}

@media (width <= 768px) {
  .meditation-content {
    padding: 15px;
  }

  .meditation-content:not(.decision-tree-open) {
    padding-top: 30px;
  }

  .text-container {
    width: 95%;
    margin: 15px auto;
  }

  .text-container.prompt {
    height: 180px;
  }

  .text-container.script {
    height: 250px;
  }

  .wave-btn-container {
    margin: 4px 0;
  }

  .text-area {
    font-size: 14px;
    padding: 12px;
  }
}

@media (width <= 480px) {
  .meditation-content {
    padding: 10px;
  }

  .meditation-content:not(.decision-tree-open) {
    padding-top: 20px;
  }

  .text-container {
    width: 98%;
    margin: 10px auto;
  }

  .text-container.prompt {
    height: 150px;
  }

  .text-container.script {
    height: 220px;
  }

  .text-area {
    font-size: 12px;
    padding: 10px;
  }
}

@supports (-webkit-touch-callout: none) {
  .text-container,
  .text-area {
    -webkit-overflow-scrolling: touch;
  }
}