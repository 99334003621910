/* Container styles */
.music-library-dropdown {
  margin: 0 auto 20px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #0f1012;
  color: #e0e0e0;
  width: 100%;
  max-width: 800px;
  min-width: 280px;
  padding: 10px;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 70%), -5px -5px 15px rgb(255 255 255 / 10%);
  display: flex;
  justify-content: center;
}

.music-library-dropdown select {
  width: 100%;
  min-width: 260px;
  background-color: #16181a;
  color: #e0e0e0;
  border: none;
  padding: 0.75rem;
  border-radius: 15px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
              inset -5px -5px 10px rgb(255 255 255 / 10%);
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23e0e0e0" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  text-align: center;
  text-align-last: center;
  padding-right: 30px;
}

.music-library-dropdown select:focus {
  outline: none;
  background-color: #1a1d20;
}

.music-library-dropdown.session-created select {
  animation: glowPulse 2s infinite;
}

.music-library-dropdown.music-loaded select {
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
              inset -5px -5px 10px rgb(255 255 255 / 10%),
              0 0 15px rgb(146 68 255 / 50%);
  animation: none;
}

/* Loading state styles */
.loading-state {
  width: 100%;
  min-width: 260px;
  background-color: #16181a;
  color: #e0e0e0;
  padding: 0.75rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
              inset -5px -5px 10px rgb(255 255 255 / 10%);
  animation: loadingPulse 1.5s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error-state {
  width: 100%;
  min-width: 260px;
  background-color: #16181a;
  color: #ff4444;
  padding: 0.75rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
              inset -5px -5px 10px rgb(255 255 255 / 10%),
              0 0 15px rgb(255 0 0 / 30%);
}

/* Animations */
@keyframes glowPulse {
  0%, 100% {
    box-shadow: 
      inset 5px 5px 10px rgba(0, 0, 0, 0.6),
      inset -5px -5px 10px rgba(255, 255, 255, 0.1),
      0 0 15px rgba(146, 68, 255, 0.3);
  }
  50% {
    box-shadow: 
      inset 5px 5px 10px rgba(0, 0, 0, 0.6),
      inset -5px -5px 10px rgba(255, 255, 255, 0.1),
      0 0 25px rgba(146, 68, 255, 0.6);
  }
}

@keyframes loadingPulse {
  0%, 100% {
    opacity: 1;
    box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
                inset -5px -5px 10px rgb(255 255 255 / 10%);
  }
  50% {
    opacity: 0.7;
    box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
                inset -5px -5px 10px rgb(255 255 255 / 10%),
                0 0 15px rgb(255 255 255 / 10%);
  }
}

@keyframes mixingPulse {
  0%, 100% {
    box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
                inset -5px -5px 10px rgb(255 255 255 / 10%),
                0 0 15px rgb(146 68 255 / 30%);
  }
  50% {
    box-shadow: inset 5px 5px 10px rgb(0 0 0 / 60%),
                inset -5px -5px 10px rgb(255 255 255 / 10%),
                0 0 25px rgb(146 68 255 / 50%);
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Loading spinner */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.loading-indicator .spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgb(146, 68, 255);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-indicator.mixing {
  background: transparent;
  color: rgb(146, 68, 255);
}

/* State-specific styles */
.music-library-dropdown.mixing select {
  animation: mixingPulse 2s infinite;
  cursor: wait;
  opacity: 0.8;
}

.music-library-dropdown.loading select {
  cursor: wait;
}

.music-library-dropdown.error select {
  border: 1px solid rgba(255, 0, 0, 0.3);
  box-shadow: 
    inset 5px 5px 10px rgba(0, 0, 0, 0.6),
    inset -5px -5px 10px rgba(255, 255, 255, 0.1),
    0 0 15px rgba(255, 0, 0, 0.3);
}

/* Option styles */
.music-library-dropdown select option {
  background-color: #16181a;
  color: #e0e0e0;
  padding: 8px;
  text-align: center;
}

.music-library-dropdown select option[value=""] {
  font-style: italic;
}

.music-library-dropdown.mixing select option[value=""] {
  color: rgb(146, 68, 255);
}

/* Hover and disabled states */
.music-library-dropdown select:hover:not(:disabled) {
  background-color: #1d1f22;
}

.music-library-dropdown select:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #14161a;
}

/* Browser-specific styles */
@-moz-document url-prefix() {
  .music-library-dropdown select {
    text-align: -moz-center;
    text-align-last: center;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .music-library-dropdown select {
      text-align: -webkit-center;
    }
  }
}

@supports (-ms-ime-align:auto) {
  .music-library-dropdown select {
    background-position: right 0.5rem center;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .music-library-dropdown {
    font-size: 14px;
  }
  
  .music-library-dropdown select {
    padding: 0.6rem;
  }
}

@media (max-width: 480px) {
  .music-library-dropdown {
    font-size: 12px;
    margin-bottom: 2rem;
    margin-top: 0.75rem;
  }
  
  .music-library-dropdown select {
    padding: 0.5rem;
  }
}